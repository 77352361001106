<template src="./activityManageAssignments.html"></template>

<script>
import PortalFooter from "../../Components/PortalFooter";
import SubjectsRepository from "../../Repository/Subject";
import SemesterRepository from "../../Repository/Semester";
import NewExamRepository from "../../Repository/NewExam";
import showStatus from '../../NetworkManager/showStatus'
import UserRepository from "../../Repository/User";
import { convertDate, timeStringConverter } from "../../Services/Utils/DateTimeFormatter"

export default {
  name: "activityManageAssignments",
  components: {
    PortalFooter
  },
  props: [
    'prop_department',
    'prop_course',
    'prop_selectedSubject',
    'prop_typeDivGrpBatch',
    'prop_selectedDivision',
    'prop_selectedBatch',
    'prop_selectedGroup',
    'prop_selectedSemester',
    'prop_selectedFaculty'
  ],

  data() {
    return {
      blue: "#050D92",
      backgroundColor: "#E7E7FFCF",
      orange: "#FF4F1F",
      downArrow: "mdi-chevron-down",
      upArrow: "mdi-arrow-up",
      examsLoaded: true,
      isMobileView: false,
      assignedExam: [],
      assignedExamDetails: {},
      academicDataTree: {},
      subjectId: [],
      filteredSubjectId: [],
      courseYears: [],
      subjectNamesById: [],
      courseName: [],
      departments: [],
      divisionName: [],
      semestersList: [],
      divisions: [],
      departmentName: [],
      selectedDivision: "",
      selectedFaculty: {},
      course: "",
      department: "",
      allExamsDetails: [],
      selectedSemester: '',
      selectedSubject: null,
      facultySubjects: [],
      typeForDivOrGrpOrBatch: ['Division', 'Group', 'Batch'],
      typeDivGrpBatch: '',
      batches: [],
      selectedBatch: {},
      subjectGroups: [],
      selectedGroup: {},
      allExams: [],
      semestersList1: [],
      examTableHeaders: [
        {
          text: 'Name',
          value: 'title'
        },
        {
          text: 'Allowed File types',
          value: 'allowedFileTypes'
        },
        {
          text: 'Published On',
          value: 'convertedPublishedDate'
        },
        {
          text: 'Due',
          value: 'convertedDueDate'
        },
        {
          text: 'Time',
          value: 'convertedTime'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      filteredExams: [],
      assignedCourseYearForSubAdmin: [],
      divisionListOfSem: [],
      subjectExams: [],
      assignedDepartment: [],
      showModal: false,
      examToDelete: null,
      facultiesNameWithUid: []
    };
  },
  async created() {
    // this.$store.commit("liveData/set_selectedActivityName", `My Exam`);
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.user = this.$store.getters["user/get_user"];
    this.userData = this.$store.getters["user/get_userData"];
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.$store.commit('liveData/set_selectedActivityName', 'Manage Assignments')
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    await this.getSemesters()
    // this.subjectId = this.userData.subjects.map((item) => item.subjectId);
    this.fetchDepartments()
    // this.departments = [
    //   ...new Set(this.userData.subjects.map((item) => item.department))
    // ];
    if (this.prop_selectedSemester) {
      this.selectedSemester = this.prop_selectedSemester
      await this.fetchDepartments()
    }
    if (this.prop_department) {
      this.department = this.prop_department
      this.selectDepartment()
    }
    if (this.prop_course) {
      this.course = this.prop_course
      await this.selectCourseYear()
    }
    if (this.prop_selectedSubject) {
      this.selectedSubject = this.prop_selectedSubject
      this.getGroupsOfSubjects()
    }
    if (this.prop_typeDivGrpBatch) this.typeDivGrpBatch = this.prop_typeDivGrpBatch
    if (this.prop_selectedDivision) {
      this.selectedDivision = this.prop_selectedDivision
      // this.filterDivAssignments()
    }
    if (this.prop_selectedBatch) {
      await this.getDivisionBatches()
      this.selectedBatch = this.prop_selectedBatch
      // await this.filterBatchAssignments()
    }
    if (this.prop_selectedGroup?.groupId) {
      this.selectedGroup = this.prop_selectedGroup
      // await this.filterGroupAssignments()
    }
    console.log('prop_selectedFaculty', this.prop_selectedFaculty)
    if (this.prop_selectedFaculty) {
      await this.fetchFacultyNames()
      this.selectedFaculty = this.prop_selectedFaculty
      await this.filterFacultyAssignments()
    }
  },
  methods: {
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semestersList1 = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDepartments() {
      this.$store.commit("liveData/set_editingSemester", this.selectedSemester);
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.divisionListOfSem = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        console.log('this.divisionListOfSem', this.divisionListOfSem);
        this.departments = []
        this.courseYears = []
        this.divisions = []
        this.divisionListOfSem.map((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
        let subAdminAssignedDepartmentWiseLecture = []
        if (this.assignedDepartment?.length > 0) {
          this.departments.map((data) => {
            if (this.assignedDepartment.includes(data)) {
              subAdminAssignedDepartmentWiseLecture.push(data)
            }
          })
          this.departments = subAdminAssignedDepartmentWiseLecture
        }
        await this.getSemestersList();
        console.log('this.departments', this.departments)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchCourseYear() {
      this.courseYears = []
      this.divisions = []
      console.log('this.divisionListOfSem22', this.divisionListOfSem)
      this.divisionListOfSem.map((div) => {
        console.log('div', div)
        if (div.department === this.department)
          if (!this.courseYears.includes(div.courseYear)) {
            this.courseYears.push(div.courseYear)
          }
      })
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin?.length > 0) {
        this.assignedCourseYearForSubAdmin?.map((cy) => {
          let [course, department1] = cy?.split('_')
          if (department1 === this.department) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYears = allowedCourseYearForSelectedDepartment
      }
      console.log('this.courseYears', this.courseYears)
    },
    async fetchSubjects() {
      try {
        console.log('fetchSubjects', this.course)
        let list = await this.subjectsRepositoryInstance.getSubjectsOfACourseYearInASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.department,
          courseYear: this.course
        });
        // if(this.type === 'Group'){
        //   list = list.filter(i=>i.groupForSubject?.length>0)
        // }
        this.facultySubjects = list
        // console.log('this.subjectsList', this.subjectsList);
        // console.log('this.selectedSubject', this.selectedSubject);

      } catch (e) {
        console.log('e', e);
      }
    },
    async getExams() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.department,
        courseYear: this.course
      }
      this.allExams = await this.newExamRepositoryInstance.getExams(objToPush)
      this.allExams.map((exam) => {
        exam.convertedPublishedDate = exam.publishDate !== undefined ? convertDate(exam.publishDate) : exam.publishDate
        exam.convertedTime = exam.dueTime !== undefined ? timeStringConverter(exam.dueTime) : exam.dueTime
        exam.convertedDueDate = exam.dueDate !== undefined ? convertDate(exam.dueDate) : exam.dueDate
      })
    },
    async getSemestersList() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        };
        this.semestersList =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        console.log('this.semestersList', this.semestersList)
      } catch (e) {
        console.error("e", e);
      }
      this.loading = false;
    },
    selectDepartment() {
      this.selectedCourseYear = "";
      this.course = "";
      this.typeDivGrpBatch = ""
      this.selectedDivision = "";
      this.assignedExam = []
      this.facultySubjects = []
      this.selectedSubject = null
      this.typeDivGrpBatch = ''
      this.subjectGroups = []
      this.selectedGroup = {}
      this.filteredExams = []
      // this.courseYears = this.semestersList.filter(item => item.department === this.department).map(data => data.courseYear)
      // this.selectCourseYear()
      this.fetchCourseYear()
      console.log('here1')
      this.$store.commit("liveData/set_examDepartment", this.department);
    },
    async selectCourseYear() {
      this.selectedDivision = "";
      this.typeDivGrpBatch = ""
      this.assignedExam = []
      this.facultySubjects = []
      this.selectedSubject = null
      this.typeDivGrpBatch = ''
      this.subjectGroups = []
      this.selectedGroup = {}
      this.filteredExams = []
      console.log('course123', this.course)
      this.fetchSubjects()
      // console.log('this.semestersList', this.semestersList1);
      this.divisions = this.semestersList.filter(item => item.department === this.department && item.courseYear === this.course).map(data => data.division)
      console.log('this.semestersList', this.semestersList, this.divisions)
      this.$store.commit("liveData/set_examCourseYear", this.course);
      try {
        // const facultySubjects = await this.subjectsRepositoryInstance.getSubjectsTaughtByAFaculty({
        //   uId: this.userData.uId,
        //   instituteId: this.selectedInstitute,
        //   semId: this.selectedSemester.semId,
        //   department: this.department,
        //   courseYear: this.course
        // })
        // this.facultySubjects = facultySubjects.subjects
      } catch (error) {
        console.error(error);
      }
      await this.getExams()
    },
    async typeSelected() {
      this.selectedGroup = {}
      this.selectedDivision = ""
      this.selectedBatch = ''
      this.filteredExams = []
      await this.fetchFacultyNames()
    },
    async fetchFacultyNames() {
      try {
        this.facultiesNameWithUid = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: this.selectedSubject.faculties,
        });
      } catch (error) {
        console.log('error', error);
      }
    },
    filterSubjectExams() {
      // this.allExams =
      this.subjectExams = this.allExams.filter(exam => exam.subjectIds.includes(this.selectedSubject.subjectId))
      console.log('this.subjectExams', this.subjectExams)
    },
    filterDivAssignments() {
      this.filteredExams = []
      if (this.typeDivGrpBatch === 'Division') {
        this.filteredExams = this.subjectExams.filter(exam =>
          (exam?.division === this.selectedDivision) && (!exam.batch || exam.batch === '')
        ).reverse();
      }
    },
    filterGroupAssignments() {
      this.filteredExams = []
      if (this.typeDivGrpBatch === 'Group') {
        this.filteredExams = this.subjectExams.filter(exam => (exam?.assignedGroupForSubject?.groupName === this.selectedGroup.groupName)).reverse()
      }
    },
    filterBatchAssignments() {
      this.filteredExams = []
      if (this.typeDivGrpBatch === 'Batch') {
        this.filteredExams = this.subjectExams.filter(exam => (exam?.batch === this.selectedBatch.batchName && exam?.division === this.selectedDivision) && (exam?.uId === this.selectedFaculty.uId || !exam.uId)).reverse()
      }
    },
    filterFacultyAssignments() {
      this.filteredExams = []
      if (this.typeDivGrpBatch === 'Division') {
        this.filteredExams = this.subjectExams.filter(exam =>
          ((exam?.division === this.selectedDivision) && (!exam.batch || exam.batch === '')) && (exam?.uId === this.selectedFaculty.uId  || !exam.uId)
        ).reverse();
      }
      if (this.typeDivGrpBatch === 'Group') {
        this.filteredExams = this.subjectExams.filter(exam => (exam?.assignedGroupForSubject?.groupName === this.selectedGroup.groupName) && (exam?.uId === this.selectedFaculty.uId  || !exam.uId)).reverse()
      }
      if (this.typeDivGrpBatch === 'Batch') {
        this.filteredExams = this.subjectExams.filter(exam => (exam?.batch === this.selectedBatch.batchName && exam?.division === this.selectedDivision) && (exam?.uId === this.selectedFaculty.uId || !exam.uId)).reverse()
      }
      // this.filteredExams = this.subjectExams.filter(exam => (exam?.uId === this.selectedFaculty.uId)).reverse()
    },
    async getDivisionBatches() {
      this.selectedBatch = ''
      this.filteredExams = []
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.department,
          courseYear: this.course,
          division: this.selectedDivision
        };
        this.batches =
          await this.semesterRepositoryInstance.getBatchesDetails(
            objToPush
          );
        console.log('this.batches', this.batches)
      } catch (e) {
        console.error("e", e);
      }

      this.loading = false;
    },
    getGroupsOfSubjects() {
      this.typeDivGrpBatch = ''
      this.filteredExams = []
      this.subjectGroups = this.selectedSubject.groupForSubject
      this.filterSubjectExams()
    },
    goToCreateExam(exam) {
      const objToPush = {
        prop_department: this.department,
        prop_course: this.course,
        prop_selectedSubject: this.selectedSubject,
        prop_typeDivGrpBatch: this.typeDivGrpBatch,
        prop_selectedDivision: this.selectedDivision,
        prop_selectedBatch: this.selectedBatch,
        prop_selectedGroup: this.selectedGroup,
        prop_selectedSemester: this.selectedSemester,
        prop_selectedFaculty: this.selectedFaculty
      }
      if (exam) {
        objToPush['prop_exam'] = exam
      }
      this.$router.push({
        name: 'activityCreateAssignments',
        params: objToPush
      })
    },
    async goToDeleteExam() {
      console.log('this.examToDelete', this.examToDelete)
      const exam = this.examToDelete
      try {
        const status = await this.newExamRepositoryInstance.deleteExam({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: exam.examId
        })
        showStatus("Successfully deleted the assignment.", 2000, 'success', this);
        console.log('status', status)
        await this.getExams()
        this.filterSubjectExams()
        if (this.typeDivGrpBatch === 'Division') {
          this.filterDivAssignments()
        }
        if (this.typeDivGrpBatch === 'Group') {
          this.filterGroupAssignments()
        }
        if (this.typeDivGrpBatch === 'Batch') {
          this.filterBatchAssignments()
        }
      } catch (e) {
        console.error("e", e);
      }
      this.showModal = false
    }
  }
};
</script>

<style scoped src="./activityManageAssignments.css"></style>
