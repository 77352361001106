<template src="./activityStudentProfile.html"></template>
<script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
<script>
import SemesterRepository from "../../Repository/Semester";
import showStatus from "../../NetworkManager/showStatus";
import ApexCharts from "apexcharts";
import { subjectTypes } from "../../Constants/Utils/Statics";
import inputContainer from "../../Components/inputContainer";
import ExamStudentRepository from "../../Repository/ExamStudent";
import AssignmentUserRepository from "../../Repository/AssignmentUser";
import InstituteUserRepository from "../../Repository/InstituteUser";
import InstituteRepository from "../../Repository/Institute";
import UserRepository from "../../Repository/User";
import LectureRepository from "../../Repository/Lecture";
import SubjectsRepository from "../../Repository/Subject";
import AssignmentRepository from "../../Repository/Assignment";
import TopicRepository from "../../Repository/Topic";
import TopicFeedbackRepository from "../../Repository/TopicFeedback";
import SemesterUserRepository from "../../Repository/SemesterUser";
import ExamSettingsRepository from "../../Repository/ExamSettings";
import numOps from "../../Services/Utils/numOps";
import arrOps from "../../Services/Utils/arrayOps";
import StudentMarksheet from "../../Components/exams/StudentMarksheet/StudentMarksheet.vue";
import StudentMarksheetStmiras from "../../Components/exams/StudentMarksheetStmiras/StudentMarksheetStmiras.vue";
import { createExcelThroughJson } from "../../utils/excel";
import {
  courseYearNumbers,
  courseInitialStMiras,
  currentCourseYearInitialStMiras,
} from "../../Constants/Utils/Statics";
import {
  addMinutes,
  compareAsc,
  isFuture,
  isWithinInterval,
  parseISO,
} from "date-fns";
import ExamRepository from "../../Repository/Exam";
import apiV3 from "../../NetworkManager/apiV3";
import NbaRepository from "../../Repository/NBA";
import html2pdf from "html2pdf.js";

import auth from "../../Services/Firebase/auth";

import {
  convertDate,
  convertTime,
} from "../../Services/Utils/DateTimeFormatter";

export default {
  name: "activityStudentProfile",
  components: {
    inputContainer,
    StudentMarksheet,
    StudentMarksheetStmiras,
  },

  data() {
    return {
      selectedAssignemntSubjectId: "",
      assignedCourseYearForSubAdmin: [],
      assignedDepartment: [],
      assignemtAnalysisDetailsArray: [],
      assignmentSubjectNamesWithId: [],
      examSubjectNamesWithId: [],
      assignemntSubjectName: "",
      allSubmittedAssignments: [],
      assignments: [],
      isTableData: true,
      topicDetails: [],
      lecturesList: {},
      subjects: [],
      studentSemester: {},
      semesters: [],
      subjectsMappingData: {},
      assignmentPercentage: 0,
      assignmentAnalysis: {},
      selectedStudentDivision: "",
      selectedStudentBatch: "",
      selectedStudentCourseYear: "",
      selectedStudentDepartment: "",
      studentDOB: "",
      selectedStudentDetails: {},
      selectedStudentUId: "",
      studentData: [],
      studentFullName: [],
      selectedStudent: "",
      fullNamesOfStudent: [],
      studentIdArray: [],
      studentNames: [],
      tabs: null,
      instituteUsers: "",
      selectedSemesterName: "",
      semestersList: [],
      items1: [
        "Basic Information",
        "Attendence Analysis",
        "Performance Analysis",
        "Assignment Status",
      ],
      divisionName: "",
      depatmentName: "",
      courseYear: "",
      idNumber: "",
      username: "",
      gender: "",
      birthdate: "",
      academicYear: "",
      contactNumber: "",
      emailId: "",
      parentName: "",
      userData: {},
      selectedInstitute: "",
      selectedSemester: "",
      topicsData: [],
      topicFeedbacks: [],
      selectedFeedback: {},
      totalPresent: 0,
      totalAbsent: 0,
      justify: "space-around",
      defaulterLimit: 75,
      loading: false,
      topicIds: [],
      topicsMapping: {},
      attendanceAnalysis: {},
      // selectedSemester: {},
      items: [],
      selectedTopicId: "",
      questions: [],
      tempAnswers: [],
      headers: [
        {
          text: "Topic Name",
          value: "topicName",
          width: "30%",
        },
        {
          text: "Date",
          value: "date",
          width: "30%",
        },
        {
          text: "Status",
          value: "status",
          width: "30%",
        },
        {
          text: "",
          value: "action",
          width: "20%",
        },
      ],
      subjectTypeWiseSubjects: {},
      resultPublicationDate: "",
      subjectNamestoIds: {},
      selectedSubjectType: "",
      subjectTypes: subjectTypes,
      subjects: [],
      subjectIds: [],
      semestersListarr: [],
      selectedSubjectString: "",
      selectedSubject: {},
      isDefaulter: false,
      totalAttendedLectures: "0/0",
      refreshed: false,
      chartOptions: {
        // formatter: function (val) {
        //   if (data === undefined || data.length === 0)
        //     return 'No data'
        //   else
        //     return val
        // },
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#ff0000",
            fontSize: "14px",
          },
        },
        colors: ["#050992", "#ff0000"],
        plotOptions: {
          pie: {
            customScale: 0.8,
            donut: {
              size: "45%",
              labels: {
                show: true,
              },
            },
          },
        },
        series: [],
        chart: {
          width: 400,
          type: "donut",
        },
        labels: ["Present", "Absent"],
        legend: {
          position: "right",
          offsetY: 160,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 100,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      barOptions: {
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#ff0000",
            fontSize: "14px",
          },
        },
        series: [
          {
            name: "Attendance",
            data: [],
          },
        ],
        chart: {
          type: "bar",
          height: 257,
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "70%",
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 74,
                  color: "#ff0000",
                },
                {
                  from: 75,
                  to: 89,
                  color: "#050992",
                },
                {
                  from: 90,
                  to: 100,
                  color: "#11bc7d",
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      examSeries: [{ name: "", data: [] }],
      examOptions: {
        colors: ["#050992"],
        chart: {
          id: "exam-analysis",
          toolbar: { show: false },
        },
        xaxis: {
          categories: [],
        },
      },
      selectedTabOne: true,
      subjectsMapping: {},
      examAnalysis: {},
      examName: "",
      subjectName: "",
      examType: "",
      quickTestHeaders: [
        { text: "Name", value: "name" },
        { text: "Subject", value: "subjectName" },
        { text: "Date", value: "dateTimeUTC" },
        { text: "Duration", value: "durationInMins" },
        { text: "Passing Percent", value: "passingPercent" },
        { text: "Marks", value: "totalMarks" },
        // { text: "Action", value: "actions" },
      ],
      quickTest: [],
      exams: [],
      instituteData: null,
      isLoadingExams: false,
      isLoadingExamsInfo: false,
      subjectsMappingArray: [],
      selectedSubjectId: "",
      subjectAverage: 0,
      hasNoData: false,
      analysisTableHeaders: [
        { text: "Assiginment Name", value: "assignmentName" },
        { text: "Submitted On", value: "submittedOn" },
        { text: "Obtained Marks", value: "obtainedMarks" },
        { text: "Total Marks", value: "totalMarks" },
      ],
      analysisTableItems: [],
      assignmentSubjectNames: [],
      chartbyId: "",
      barbyId: "",
      dataItems: [],
      selectedAssessmentType: "primary",
      studentResultDataHeader: [
        {
          text: "Roll No.",
          value: "rollNumber",
        },
        {
          text: "Student Name",
          value: "name",
        },
        {
          text: "Publish Result",
          value: "publishResult",
          sortable: false,
        },
      ],
      allCourseYears: [],
      allDepartments: [],
      selectedDepartment: "",
      studentNameLoader: false,
      selectedCourseYear: "",
      selectedDepartment: "",
      semesterUserDetails: [],
      batchSemestersList: [],
      studentResultData: [],
      selectedBatch: {},
      allBatches: [],
      batchData: [],
      resultCalculationMethod: "",
      semesterResultStatus: {},
      instituteSubjects: [],
      instituteLogoLink: "",
      instituteAddress: "",
      instituteName: "",
      pdfDownloadLoader: false,
      studentPersonalDetails: [],
      currentBatchSemIds: [],
      isLastYear: false,
      marksheetLoader: false,
      selectedStudentFilter: "all",
      studentFilters: [
        {
          text: "Passed Students",
          value: "passed",
        },
        {
          text: "Failed Students",
          value: "failed",
        },
        {
          text: "All Students",
          value: "all",
        },
        // {
        //   text: "Appeared Students",
        //   value: "appeared",
        // },
      ],
      search: "",
      filteredStudents: [],
      batchSubjectDetails: [],
      selectedSubjectToShowGradeRange: {},
      showGradeRangeDialog: false,
      gradeRangeHeaders: [
        {
          text: "Grade Name",
          value: "gradeName",
        },
        {
          text: "Grade Points",
          value: "gradePoints",
        },
        {
          text: "Grade Range",
          value: "gradeRange",
        },
        {
          text: "Grade Remark",
          value: "gradeRemark",
        },
      ],
      gradeRangeItems: [],
      attendanceAnalysisArray: [],
      disableAction: false,
      marksheetDetailsToPush: {},
      instituteInfo: {},
      // isNicmar: false,
      customMarksheetTemplate: 0,
      lastYearOfTheSelectedBatch: {},
      semestersToPush: [],
      studentDataLoader: false,
      overallTotalCredits: 0,
      overallObtainedCredits: 0,
      overallTotalMarks: 0,
      overallObtainedMarks: 0,
      consolidatedMarksheetExcel: 1,
      totalStudentsPerGrade: {},
      modifiedSemesterName: "",
      isStmiras: false,
      changedItems: [],
      isResultPublishForAllStudents: false,
      studentsForStatementNo: [],
      totalInternalMarks: 0,
      totalExternalMarks: 0,
      noDataText: "No Data Available.",
      certificateNumber: null,
      showCgpa: false,
      showSgpa: false,
      currentInstitute: "",
      currentSemesterNumber: 0,
    };
  },
  mounted() {
    this.chartbyId = document.getElementById("chart");
    this.barbyId = document.getElementById("bar");
  },
  async created() {
    // 2939ed29-4649-4043-8498-f4eb3b0eac9e
    this.$store.commit("liveData/set_selectedActivityName", "Search Students");
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);
    this.assignmentRepositoryInstance = new AssignmentRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.topicRepositoryInstance = new TopicRepository(this);
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this);
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.instituteLogoLink = this.$store.getters["liveData/get_brandingLogo"];
    this.instituteName = this.$store.getters["liveData/get_brandingName"];
    this.instituteAddress =
      this.$store.getters["liveData/get_instituteAddress"].instituteAddress;
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    if (
      this.selectedInstitute === "6509f460-cdc3-494d-bae3-23029b3d7f04" ||
      this.selectedInstitute === "6604982c-cc08-4e00-81fb-a375a10b72e4"
    ) {
      this.isStmiras = true;
    }
    if (this.selectedInstitute === "2939ed29-4649-4043-8498-f4eb3b0eac9e")
      this.currentInstitute = "RIIMS";
    if (this.selectedInstitute === "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b")
      this.currentInstitute = "MIMA";
    this.assignedDepartment =
      this.$store.getters[
        "liveData/get_accessibleDepartment"
      ].accessibleDepartment;

    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.instituteRepositoryInstance = new InstituteRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.assignedCourseYearForSubAdmin =
      this.$store.getters["liveData/get_accessibleCourseYearForSubAdmin"]
        ?.accessibleCourseYearForSubAdmin || [];
    this.examStudentRepositoryInstance = new ExamStudentRepository(this);
    subjectTypes.forEach((subjectType) => {
      this.subjectTypeWiseSubjects[subjectType] = [];
    });
    await this.getAllSems();
    this.subjectsMappingArray = Object.keys(this.subjectsMapping).map(
      (key) => this.subjectsMapping[key]
    );
    this.subjectsMappingArray.push({ subjectId: "all", subjectName: "All" });
    const data = this.$store.getters["instituteData/get_applicableInstitutes"];
    this.instituteData = data[0];
    this.examRepositoryInstance = new ExamRepository(this);
    this.selectSemester();
    this.selectedSubjectId = "all";
    this.onSelectSubject();
    await this.getExamSettings();
    await this.getBatches();
    await this.getInstituteInformation();
    await this.getInstituteCustomizationFromFirebase();
  },
  methods: {
    handleCheckboxChange(item) {
      if (!this.changedItems.includes(item)) {
        this.changedItems.push(item);
      }
    },
    handleCheckboxChangeAll(item) {
      this.changedItems = [];
      if (this.isResultPublishForAllStudents === true) {
        this.isResultPublishForAllStudents === false;
        item.forEach((item) => {
          if (item.publishResult === false) {
            item.publishResult = true;
            this.handleCheckboxChange(item);
          }
        });
      } else {
        this.isResultPublishForAllStudents === true;
        item.forEach((item) => {
          item.publishResult = false;
          this.handleCheckboxChange(item);
        });
      }
    },
    async publishResultToStudents() {
      let temp = this.changedItems;
      const arr = [];
      temp.forEach((item, index) => {
        let objToPush = {};
        objToPush["publishResult"] = item.publishResult;
        objToPush["uId"] = item.uId;
        objToPush["instituteId"] = item.instituteId;
        objToPush["semId"] = item.semId;
        arr.push(objToPush);
      });
      try {
        const res =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: arr,
          });
        if (res.status === 200) {
          showStatus("Result published successfully", 2000, "success", this);
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (error) {
        console.error("error", error);
      }
      this.changedItems = [];
    },
    async selectSemester() {
      this.studentNameLoader = true;
      this.filteredStudents = [];
      this.selectedStudent = "";
      this.semestersListarr.map((obj) => {
        if (this.selectedSemesterName === obj.semName) {
          this.selectedSemester = obj;
        }
      });
      this.noDataText = "Fetching student names...";
      // if(this.certificateNumber === 2) {
      //   this.currentBatchSemIds = [this.selectedSemester.semId]
      //   await this.getSemesterDetails(this.currentBatchSemIds);
      //   await this.getSemesterSubjects()
      // }
      // if(this.isLastYear) {
      if (this.selectedSemester) {
        this.currentSemesterNumber =
          this.currentBatchSemIds.indexOf(this.selectedSemester.semId) + 1;
      }
      await this.getStudentNames(this.isLastYear);
      // }
      // this.getStudentNames();
    },
    async getInstituteCustomizationFromFirebase() {
      const result = await auth.getInstituteCustomizationFromFirebase(
        this.selectedInstitute
      );
      this.resultPublicationDate = result.resultPublicationDate;
      if (result.marksheetTemplate) {
        this.customMarksheetTemplate = result.marksheetTemplate;
      }
      if (result.consolidatedMarksheetExcel) {
        this.consolidatedMarksheetExcel = result.consolidatedMarksheetExcel;
      }
    },
    async getInstituteInformation() {
      this.instituteInfo =
        await this.instituteRepositoryInstance.getInstituteInformation({
          instituteId: this.selectedInstitute,
        });
      if (this.instituteInfo.marksheetTemplate) {
        this.showCgpa = this.instituteInfo.marksheetTemplate.includeCgpaTable;
        this.showSgpa = this.instituteInfo.marksheetTemplate.includeSgpaTable;
        this.certificateNumber =
          this.instituteInfo.marksheetTemplate.certificateType.value;
        this.instituteInfo.marksheetTemplate.tableData = [];
        this.instituteInfo.marksheetTemplate.headersData = [];
        this.instituteInfo.marksheetTemplate.semesterTableData = [];
        this.instituteInfo.marksheetTemplate.studentPersonalDetails = [];
        this.instituteInfo.marksheetTemplate.personalDetails.map((item) => {
          let objToPush = {};
          if (item === "Student Name") {
            objToPush = {
              text: "Student Name",
              value: "name",
            };
          }
          if (item === "Mother's Name" && this.certificateNumber === 5) {
            objToPush = {
              text: "Mother's Name",
              value: "motherName",
            };
          }
          if (item === "Roll No") {
            objToPush = {
              text: "Roll No",
              value: "rollNumber",
            };
          }
          if (item === "PRN") {
            objToPush = {
              text:
                this.currentInstitute === "MIMA"
                  ? "AICTE Enrollment No"
                  : "PRN",
              value: "studentPrn",
            };
          }
          this.instituteInfo.marksheetTemplate.studentPersonalDetails.push(
            objToPush
          );
        });
        if (this.currentInstitute === "MIMA") {
          this.instituteInfo.marksheetTemplate.tableData.push({
            text: "Sr. No.",
            value: "srNo",
          });
        }
        this.instituteInfo.marksheetTemplate.tableFields.map((item) => {
          let objToPush = {};
          if (item === "Course Code") {
            objToPush = {
              text: "Course Code",
              value: "subjectCode",
            };
          }
          if (item === "Course Name") {
            objToPush = {
              text: "Course Name",
              value: "subjectName",
            };
          }
          if (item === "Course Type") {
            objToPush = {
              text: "Course Type",
              value: "subjectType",
            };
          }
          if (item === "Credits") {
            if (this.currentInstitute === "RIIMS") {
              objToPush = {
                text: "Cr",
                value: "obtainedCredits",
              };
            } else {
              objToPush = {
                text: "Credits",
                value: "obtainedCredits",
              };
            }
          }
          if (item === "Grade Point") {
            if (this.currentInstitute === "RIIMS") {
              objToPush = {
                text: "GP",
                value: "gradePoints",
              };
            } else {
              objToPush = {
                text: "Grade Point",
                value: "gradePoints",
              };
            }
          }
          if (item === "Grade") {
            if (this.currentInstitute === "RIIMS") {
              objToPush = {
                text: "Gr",
                value: "obtainedGrade",
              };
            } else {
              objToPush = {
                text: "Grade",
                value: "obtainedGrade",
              };
            }
          }
          if (item === "Internal") {
            objToPush = {
              text: this.currentInstitute === "RIIMS" ? "CCE" : "Internal",
              value: "totalObtainedInternalMarks",
            };
          }
          if (item === "External") {
            objToPush = {
              text: this.currentInstitute === "RIIMS" ? "ESE" : "External",
              value: "totalObtainedExternalMarks",
            };
          }
          if (item === "Total") {
            objToPush = {
              text: "Total",
              value: "totalObtainedMarks",
            };
          }
          if (item === "Grade Point Average") {
            if (this.currentInstitute === "RIIMS") {
              objToPush = {
                text: "CP",
                value: "creditPoints",
              };
            } else {
              objToPush = {
                text: "Grade Point Average",
                value: "creditPoints",
              };
            }
          }
          this.instituteInfo.marksheetTemplate.tableData.push(objToPush);
        });
        let headersData = [
          {
            text: "Gr",
            value: "Grade",
          },
          {
            text: "Cr",
            value: "Credits",
          },
          {
            text: "GP",
            value: "Grade Points",
          },
          {
            text: "CP",
            value: "Credit Points",
          },
        ];
        let semesterTableData = [
          {
            text: "Semester",
            value: "semName",
          },
          {
            text: "Total Marks",
            value: "totalMarks",
          },
          {
            text: "Total Marks Obtained",
            value: "obtainedMarks",
          },
          {
            text: "Percentage",
            value: "finalPercentage",
          },
          {
            text: "Credits",
            value: "totalCredits",
          },
          {
            text: "EGP",
            value: "egp",
          },
          {
            text: "Grade",
            value: "finalGrade",
          },
          {
            text: "SGPA",
            value: "sgpa",
          },
        ];
        this.instituteInfo.marksheetTemplate.semesterTableData =
          semesterTableData;
        if (this.currentInstitute === "RIIMS") {
          this.instituteInfo.marksheetTemplate.headersData = headersData;
          this.instituteInfo.marksheetTemplate.tableData.unshift({
            text: "Sem",
            value: "semNumber",
          });
        }
      }
    },
    assignGrade(marks) {
      let gradeLetter = "";
      let gradePoint = 0;
      let gradeRemark = 0;
      let tempGrade = this.examSettings.gradeDetails.filter(
        (e) =>
          Number(marks) <= Number(e.gradeRange.from) &&
          Number(marks) >= Number(e.gradeRange.to)
      )[0];
      if (tempGrade) {
        gradeLetter = tempGrade.gradeName;
        gradeRemark = tempGrade.gradeRemark;
        gradePoint = Number(tempGrade.gradePoints).toFixed(1);
      } else {
        gradeLetter = "F";
        gradePoint = 0;
      }
      return { gradeLetter, gradeRemark };
    },
    roundOffUptoTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    },
    async getExamSettings() {
      try {
        const result =
          await this.examSettingsRepositoryInstance.getInstituteExamSetting({
            instituteId: this.selectedInstitute,
          });
        this.examSettings = result.result[0];
      } catch (error) {
        this.examSettings = {};
        console.error("error", error);
      }
    },
    async generateConsolidatedExcel() {
      this.marksheetLoader = true;
      // this.consolidatedMarksheetExcel === 1
      let finalHeaders = [
        {
          name: "PRN",
          row: "1-3",
          col: "1-2",
          value: "studentPrn",
        },
        {
          name: "Roll No",
          row: "1-3",
          col: "2-3",
          value: "rollNo",
        },
        {
          name: "Student Name",
          row: "1-3",
          col: "3-4",
          value: "name",
        },
      ];
      let uniqueSubjects = [];
      this.filteredStudents.map((student) => {
        student.semesterSubjectMarks.map((subject, i) => {
          if (!uniqueSubjects.find((item) => item === subject.subjectName)) {
            uniqueSubjects.push(subject.subjectName);

            if (this.consolidatedMarksheetExcel === 1) {
              finalHeaders.push(
                {
                  name: subject.subjectName,
                  row: "1-2",
                  key: i + 1,
                  value: "",
                },
                {
                  name: "Internal",
                  row: "1-2",
                  value: "totalObtainedInternalMarks",
                },
                {
                  name: "External",
                  row: "1-2",
                  value: "totalObtainedExternalMarks",
                },
                {
                  name: "Grace",
                  row: "1-2",
                  value: "totalObtainedGraceMarks",
                },
                {
                  name: "Total",
                  row: "1-2",
                  value: "totalObtainedMarks",
                },
                {
                  name: "Grade",
                  row: "1-2",
                  value: "obtainedGrade",
                }
              );
            } else {
              finalHeaders.push(
                {
                  name: subject.subjectName,
                  row: "1-2",
                  key: i + 1,
                  value: "",
                },
                {
                  name: "Internal",
                  row: "1-2",
                  value: "totalObtainedInternalMarks",
                },
                {
                  name: "External",
                  row: "1-2",
                  value: "totalObtainedExternalMarks",
                },
                {
                  name: "Total",
                  row: "1-2",
                  value: "obtainedMarks",
                },
                {
                  name: "Grade",
                  row: "1-2",
                  value: "obtainedGrade",
                }
              );
            }
          }
        });
      });

      let obj = {};
      if (this.consolidatedMarksheetExcel === 1) {
        finalHeaders.push(
          {
            name: "Grand Total",
            row: "1-3",
            value: "totalSubjectObtainedMarks",
          },
          {
            name: "% marks",
            row: "1-3",
            value: "finalPercentage",
          },
          {
            name: "Pass / Fail",
            row: "1-3",
            value: "status",
          },
          {
            name: "SGPA",
            row: "1-3",
            value: "finalSgpa",
          },
          {
            name: "CGPA",
            row: "1-3",
            value: "finalCgpa",
          }
        );
      } else {
        finalHeaders.push(
          {
            name: "% marks",
            row: "1-3",
            value: "finalPercentage",
          },
          {
            name: "SGPA",
            row: "1-3",
            value: "finalSgpa",
          },
          {
            name: "CGPA",
            row: "1-3",
            value: "finalCgpa",
          }
        );
      }
      let subjectDetailColumnCount =
        this.consolidatedMarksheetExcel === 1 ? 5 : 4;
      let tailLength = 3;

      let student = this.filteredStudents.map((e) => {
        return {
          rollNumber: e.rollNumber,
          name: e.name,
          studentPrn: e.studentPrn,
          finalCgpa: e.finalCgpa,
          finalSgpa: e.allSgpas[0] ? e.allSgpas[0].sgpa : 0,
          finalPercentage: e.finalPercentage,
          overallObtainedMarks: e.overallObtainedMarks,
          overallTotalMarks: e.overallTotalMarks,
          semesterSubjectMarks: e.semesterSubjectMarks,
        };
      });
      let objToPush = {
        uniqueSubjects: uniqueSubjects,
        studentData: student,
      };
      try {
        const data =
          await this.examStudentRepositoryInstance.generateConsolidatedMarksheetExcel(
            objToPush
          );

        let excelName = `${this.instituteInfo.instituteName}_${this.selectedSemesterName}_${this.selectedCourseYear}_${this.selectedDepartment}_Consolidated-Marksheet.xlsx`;
        this.downloadFileViaBuffer(data.data, excelName);
      } catch (error) {
        console.error("error", error);
      }
      this.marksheetLoader = false;
    },
    async getStudentNames(isLastYear) {
      this.fullNamesOfStudent = [];
      this.studentData = [];
      this.studentIdArray = [];
      try {
        let result =
          await this.semesterUserRepositoryInstance.getStudentsByInstituteAndDepartment(
            {
              instituteId: this.selectedInstitute,
              department: this.selectedDepartment,
            }
          );
        // isLastYear = false;
        if (isLastYear && this.certificateNumber === 5) {
          let semIds = []
          this.semestersListarr.forEach((sem) => {
            if(this.batchSemestersList.includes(sem.semName)) {
              semIds.push(sem.semId);
            }
          });
          this.studentData = result.filter(
            (item) => semIds.includes(item.semId)  && this.allCourseYears.includes(item.courseYear)
            // item.semId === this.selectedSemester.semId
          );
          // this.studentData = result;
        } else {
          this.studentData = result.filter(
            (item) => item.courseYear === this.selectedCourseYear
            // &&
            // item.semId === this.selectedSemester.semId
          );
        }
        // if (this.resultCalculationMethod === "sgpa") {
        //   for (let index = 0; index < this.studentData.length; index++) {
        //     const element = this.studentData[index];
        //     if (element.semesterSubjectMarks.length > 0) {
        //       this.semesterUserDetails.push(element);
        //     }
        //   }
        // } else {
        // const result = {};
        // this.studentData.forEach((item) => {
        //   if (!result[item.uId]) {
        //     result[item.uId] = [];
        //   }
        //   result[item.uId].push(item);
        // });

        const resultObj = {};
        let temp = this.instituteInfo.marksheetTemplate.certificateType.value;
        // if (temp === 4 || temp === 5) {
        // eb444ec3-840c-4476-91a0-5e7a0ad1e52d
        this.studentData.forEach((item) => {
          let objToPush = {};
          if (this.currentBatchSemIds.indexOf(item.semId) !== -1) {
            if (!resultObj[item.uId]) {
              resultObj[item.uId] = { ...item };
              resultObj[item.uId].semesterSubjectMarks = [];
              resultObj[item.uId].subjects = [];
              resultObj[item.uId].allSgpas = [];
            }
            resultObj[item.uId].semesterSubjectMarks.push(
              ...item.semesterSubjectMarks
            );
            resultObj[item.uId].subjects.push(...item.subjects);
            resultObj[item.uId].specialization = item.specialization;
            objToPush["semNumber"] = `${
              this.currentBatchSemIds.indexOf(item.semId) + 1
            }`;
            objToPush["semId"] = item.semId;
            objToPush["sgpa"] = item.finalSgpa.toFixed(1);
            if (this.currentInstitute === "RIIMS") {
              resultObj[item.uId].allSgpas.push(objToPush);
            } else if (this.semesterResultStatus[item.semId]) {
              resultObj[item.uId].allSgpas.push(objToPush);
            }
          }
        });
        this.semesterUserDetails = Object.values(resultObj);
        // }
        // }
      } catch (error) {
        this.noDataText = "No Data Available.";
        this.studentData = [];
        console.error("error", error);
      }
      this.studentData.map((stu) => {
        if (this.studentIdArray.indexOf(stu.uId) === -1) {
          this.studentIdArray.push(stu.uId);
        }
      });
      await this.getFullNameOfStudent();
      // await this.getSemesterUserDetails();
      this.studentNameLoader = false;
    },

    async getFullNameOfStudent() {
      this.studentFullName = [];
      try {
        this.studentFullName =
          await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: this.studentIdArray,
          });
        this.studentFullName.unshift({ fullName: "All", uId: "all" });
      } catch (error) {
        this.studentFullName = [];
        console.error("error", error);
      }
      this.studentFullName.map((std) => {
        this.fullNamesOfStudent.push(std.fullName);
      });
    },
    handleStudentFilter() {
      this.filteredStudents = [];
      if (this.selectedStudentFilter !== null) {
        if (this.selectedStudentFilter === "failed") {
          this.filteredStudents = this.studentResultData.filter(
            (item) => item.finalSgpa === 0
          );
        } else if (this.selectedStudentFilter === "passed") {
          this.filteredStudents = this.studentResultData.filter(
            (item) => item.finalSgpa !== 0
          );
        } else if (this.selectedStudentFilter === "all") {
          this.filteredStudents = this.studentResultData;
        }
      }
      // arrOps.sortByProperty(objToPush.semesterSubjectMarks, "subjectCode", 'asc')
      // this.filteredStudents
    },
    async downloadReport() {
      let objToPush = {
        headers: this.studentResultDataHeader,
        studentData: this.filteredStudents,
      };
      try {
        const result =
          await this.examStudentRepositoryInstance.generateFilteredStudentsExcel(
            objToPush
          );
        this.downloadFileViaBuffer(result.data, "FilteredStudents.xlsx");
      } catch (error) {
        console.error("error", error);
      }
    },
    calculateStudentResult(studentId) {
      let tempStudentData = [];
      this.studentResultDataHeader.splice(3);
      if (this.examSettings.systemPreference === "percentage") {
        this.studentResultDataHeader.push(
          {
            text: "Marks",
            value: "marks",
          },
          {
            text: "Percentage",
            value: "finalPercentage",
          },
          {
            text: "Number of backlogs",
            value: "noOfBacklogs",
            align: "center",
          },
          {
            text: "Pass/Fail",
            value: "status",
            align: "center",
          }
        );
      } else {
        this.studentResultDataHeader.push(
          {
            text: "Total Credits",
            value: "totalCredits",
          },
          {
            text: "Credit Points",
            value: "totalCreditPoints",
          },
          {
            text: "Grade Points",
            value: "totalSubjectGradePoints",
          }
        );
      }
      this.studentResultData = [];
      if (studentId === "all") {
        let length = this.semesterUserDetails.length;
        let sortedStudents = arrOps.sortByProperty(
          this.semesterUserDetails,
          "rollNumber",
          "asc"
        );
        for (let smIndex = 0; smIndex < length; smIndex++) {
          const student = sortedStudents[smIndex];
          let objToPush = this.calculateIndividualStudentResult(student, smIndex);
          if (objToPush.totalSubjectMarks && objToPush.totalSubjectMarks > 0) {
            tempStudentData.push(objToPush);
          }
        }
      } else {
        const student = sortedStudents.filter(
          (item) => item.uId === studentId
        )[0];
        if (student) {
          let objToPush = this.calculateIndividualStudentResult(student);
          if (objToPush.totalSubjectMarks && objToPush.totalSubjectMarks > 0) {
            tempStudentData.push(objToPush);
          }
        }
      }
      this.studentResultData = arrOps.sortByProperty(
        tempStudentData,
        "rollNumber",
        "asc"
      );
      // this.studentResultDataHeader.slice(-2, 2)
      // if (this.resultCalculationMethod === "sgpa") {
      this.studentResultDataHeader.push({
        text: "SGPA",
        value: "finalSgpa",
      });
      // } else {
      this.studentResultDataHeader.push({
        text: "CGPA",
        value: "finalCgpa",
      });
      // }
    },
    generateGradeSheet() {
      const allPercentages = [];
      // let arr = []
      let headObject = {
        "Sr.No.": 1,
        "Name of Programme": this.selectedDepartment,
      };
      this.studentResultData.map((student) => {
        allPercentages.push(this.roundOffUptoTwoDecimals(student.percentage));
      });
      let maxPercentage = Math.max(...allPercentages);
      const { gradeRangeToExport, totalStudentsPerGrade } =
        this.calculateProgramWiseGradeRange(maxPercentage);
      totalStudentsPerGrade["F"] = 0;

      this.filteredStudents.map((student) => {
        student.finalGrade = "";
        if (student.percentage) {
          if (
            student.percentage >= this.examSettings.lowestPercentageOfPassing
          ) {
            const grade = gradeRangeToExport.filter(
              (item) =>
                student.percentage >= item.gradeRange.from &&
                student.percentage <= item.gradeRange.to
            )[0];

            if (grade) {
              student.finalGrade = grade.gradeName;
            } else {
              student.finalGrade = "F";
            }
          } else {
            student.finalGrade = "F";
          }
          totalStudentsPerGrade[student.finalGrade]++;
        }
      });

      let totalNumberOfStudents = this.studentResultData.length;

      let passedStudents = 0;
      for (let key in totalStudentsPerGrade) {
        if (key !== "F") {
          passedStudents += totalStudentsPerGrade[key];
        }
      }

      let passedStudentsPercentage = this.roundOffUptoTwoDecimals(
        (passedStudents / totalNumberOfStudents) * 100
      );
      let failedStudentsPercentage = this.roundOffUptoTwoDecimals(
        (totalStudentsPerGrade["F"] / totalNumberOfStudents) * 100
      );
      let tailObject = {
        "No. of students appeared": totalNumberOfStudents,
        "No. of students pass": passedStudents,
        "%of passed": passedStudentsPercentage,
        "% of fail": failedStudentsPercentage,
      };
      let objToPush = {
        ...headObject,
        ...totalStudentsPerGrade,
        ...tailObject,
      };
      createExcelThroughJson([objToPush], "Grade Wise Details Excel");
    },
    generateSubjectGradeSheet() {
      const subjectData = [];
      this.studentResultData.forEach((student, index) => {
        student.semesterSubjectMarks.forEach((subject, index2) => {
          const existingSubject = subjectData.find(
            (sub) => sub.subjectId == subject.subjectId
          );
          const gradeKey = subject.obtainedGrade;
          if (existingSubject) {
            existingSubject[gradeKey] = (existingSubject[gradeKey] || 0) + 1;
          } else {
            subjectData.push({
              subjectId: subject.subjectId,
              "Sr.No.": index2 + 1,
              "Subject Name": subject.subjectName,
              [gradeKey]: 1,
            });
          }
        });
      });
      let grades = ["A", "A+", "B", "B+", "C+", "C", "D", "F", "P", "NP"];
      let fields = [
        "No. of students appeared",
        "No. of students pass",
        "% of passed",
        "% of failed",
      ];
      subjectData.forEach((subject) => {
        grades.forEach((grade) => {
          if (!Object.keys(subject).includes(grade)) {
            subject[grade] = 0;
          }
        });
        fields.forEach((field) => {
          if (!Object.keys(subject).includes(field)) {
            subject[field] = 0;
          }
        });
        let failCount = subject["F"] + subject["NP"];
        let passCount =
          subject["A"] +
          subject["A+"] +
          subject["B"] +
          subject["B+"] +
          subject["C"] +
          subject["C+"] +
          subject["D"] +
          subject["P"];
        let percentPass = (passCount / (failCount + passCount)) * 100;
        let percentFail = (failCount / (failCount + passCount)) * 100;
        subject["No. of students pass"] = passCount;
        subject["No. of students appeared"] = passCount + failCount;
        subject["% of passed"] = this.roundOffUptoTwoDecimals(percentPass);
        subject["% of failed"] = this.roundOffUptoTwoDecimals(percentFail);
        delete subject["subjectId"];
      });
      createExcelThroughJson(subjectData, "Grade Wise Details Excel");
    },
    calculateProgramWiseGradeRange(maxPercentage) {
      let gradeRangeToExport = [];
      let totalStudentsPerGrade = {};
      let numberOfGrades = this.examSettings.gradeDetails.length;
      let upperLimit = maxPercentage;
      let rangeDiff = this.roundOffUptoTwoDecimals(
        (maxPercentage - this.examSettings.lowestPercentageOfPassing) /
          numberOfGrades
      );
      this.examSettings.gradeDetails.map((grade, i) => {
        totalStudentsPerGrade[`${grade.gradeName}`] = 0;
        let tempGradeDetails = {
          gradeName: grade.gradeName,
          gradePoints: grade.gradePoints.toFixed(1),
          gradeRemark: grade.gradeRemark,
        };

        let lowerLimit = this.roundOffUptoTwoDecimals(upperLimit - rangeDiff);

        let tempGradeRange = {
          from: lowerLimit,
          to: upperLimit,
        };
        tempGradeDetails["gradeRange"] = tempGradeRange;
        gradeRangeToExport.push(tempGradeDetails);

        upperLimit = this.roundOffUptoTwoDecimals(lowerLimit - 0.01);
      });
      gradeRangeToExport[numberOfGrades - 1].gradeRange.from = Number(
        this.examSettings.lowestPercentageOfPassing
      );
      return { gradeRangeToExport, totalStudentsPerGrade };
    },
    sortByAlphaNumericValue(array, fieldToSortBy) {
      array.sort((a, b) => {
        if (
          a[fieldToSortBy] === null ||
          b[fieldToSortBy] === null ||
          a[fieldToSortBy] === undefined ||
          b[fieldToSortBy] === undefined
        ) {
          // If either fieldToSortBy is null, move the element with null fieldToSortBy to the end.
          if (a[fieldToSortBy] === null || a[fieldToSortBy] === undefined)
            return 1;
          if (b[fieldToSortBy] === null || b[fieldToSortBy] === undefined)
            return -1;
        }
        return a[fieldToSortBy].localeCompare(b[fieldToSortBy]); // Compare non-null fieldToSortBy strings
      });
      return array;
    },
    calculateIndividualStudentResult(student, sIndex) {
      let currentSemId = this.semestersListarr.filter(
        (s) => s.semName == this.selectedSemesterName
      )[0].semId;
      let students = this.studentsForStatementNo
        .filter((s) => s.semId == currentSemId)
        .filter((s) => s.department == this.selectedDepartment);
      let statementNumber;
      if (students.length > 0 && this.isStmiras) {
        // students.sort((a, b) => a._id - b._id);
        // let indexOfStudent =
        //   students.findIndex((s) => s._id === student._id) + 1;
        // console.log("indexOfStudent", indexOfStudent);
        let indexOfStudent = sIndex + 1
        let formattedIndex = (indexOfStudent + 10000).toString().substring(1);
        let dept =
          this.selectedDepartment == "BBA (CA)"
            ? this.selectedDepartment
            : this.selectedDepartment.split(" ")[0];
        let currentCY = this.selectedCourseYear.split(" ")[0];
        let firstComp = courseInitialStMiras[dept];
        let secondComp = this.selectedCourseYear.split(" ")[1].split("-")[0];
        let thirdComp = currentCourseYearInitialStMiras[currentCY];
        let cyNumber = this.selectedBatch.batch.filter(
          (b) => b.courseYear == this.selectedCourseYear
        )[0].courseYearNumber;
        let romanNumber = this.selectedSemesterName.split(" ")[1].split("/")[
          cyNumber - 1
        ];
        // console.log('romanNumber', romanNumber);
        let romanNumerals = [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ];
        let convertedRomanNumber = romanNumerals[parseInt(romanNumber) - 1];
        statementNumber =
          firstComp +
          "-" +
          secondComp +
          "-" +
          thirdComp +
          "-" +
          convertedRomanNumber +
          "-" +
          formattedIndex;
      }
      // console.log('statementNumber', statementNumber);

      let objToPush = {};
      // let obtainedMarks = 0;
      // let totalMarks = 0;
      let noOfBacklogs = 0;
      let rollNumber = "";
      let prn = "";
      let motherName = "";
      let totalSubjectMarks = 0;
      let totalSubjectObtainedMarks = 0;
      let fullName = "";
      let allSgpas = [];
      let grNumber;
      let abcIdNumber;
      let semesterSubjectMarks = student.semesterSubjectMarks
        ? student.semesterSubjectMarks
        : [];
      let personalDetails = this.studentPersonalDetails.filter(
        (item) => item.uId === student.uId
      )[0];
      rollNumber = student.rollNumber;
      if (personalDetails) {
        motherName = personalDetails.motherName
          ? personalDetails.motherName
          : "";
        prn = personalDetails.collegePRNNo;
        allSgpas = student.allSgpas ? student.allSgpas : [];
        fullName =
          personalDetails.firstName +
          " " +
          personalDetails.middleName +
          " " +
          personalDetails.lastName;
        grNumber = personalDetails.grNumber;
        // grNumber = personalDetails.abcIdNumber 12345;
        abcIdNumber = personalDetails.abcIdNumber;
      }
      objToPush["studentPrn"] = prn;
      objToPush["motherName"] = motherName;
      objToPush["rollNumber"] = rollNumber;
      objToPush["allSgpas"] = allSgpas;
      objToPush["name"] = fullName;
      objToPush["examSeatNumber"] = student.examSeatNumber;
      objToPush["grNumber"] = grNumber;
      objToPush["abcIdNumber"] = abcIdNumber;
      objToPush["uId"] = student.uId;
      objToPush["allCourseYears"] = this.allCourseYears;
      if (statementNumber && this.isStmiras) {
        objToPush["statementNumber"] = statementNumber;
      }
      objToPush["publishResult"] = student.publishResult;
      objToPush["uId"] = student.uId;
      objToPush["semId"] = student.semId;
      objToPush["instituteId"] = student.instituteId;
      objToPush["specialization"] = student.specialization;
      let length = semesterSubjectMarks.length;
      if (this.examSettings.systemPreference === "percentage") {
        for (let subIndex = 0; subIndex < length; subIndex++) {
          const subject = semesterSubjectMarks[subIndex];
          totalSubjectObtainedMarks += subject.totalObtainedMarks;
          totalSubjectMarks += subject.totalMarks;
          if (!subject.isPassed) {
            noOfBacklogs++;
          }
        }
        objToPush["marks"] = `${this.roundOffUptoTwoDecimals(
          totalSubjectObtainedMarks
        )}/${this.roundOffUptoTwoDecimals(totalSubjectMarks)}`;
        objToPush["finalPercentage"] = `${student.finalPercentage}%`;
        objToPush["status"] =
          student.passStatus && totalSubjectObtainedMarks ? "Pass" : "Fail";
        objToPush["noOfBacklogs"] = noOfBacklogs;
      } else {
        let totalCreditPoints = 0;
        let totalSubjectGradePoints = 0;
        let subjectCreditsSum = 0;
        let totalGradePointsSum = 0;
        let maximumCredits = 0;
        for (let subIndex = 0; subIndex < length; subIndex++) {
          const subject = semesterSubjectMarks[subIndex];
          let subjectCode = "";
          let subjectName = "";
          let subjectSem = "";
          let subjectDescription = "";
          for (let inSub = 0; inSub < this.instituteSubjects.length; inSub++) {
            const subj = this.instituteSubjects[inSub];
            if (subj?.subjectId === subject?.subjectId) {
              subjectCode = subj.subjectCode;
              subjectName = subj.subjectName;
              subjectDescription = subj.subjectDescription
              subjectSem = subj.semId;
              maximumCredits += subj.credits;
            }
          }
          let tempGraceMarksSymbol;
          if (
            !subject.graceMarksSymbol ||
            subject.graceMarksSymbol === "undefined"
          ) {
            tempGraceMarksSymbol = "";
          } else {
            tempGraceMarksSymbol = subject.graceMarksSymbol;
          }
          subject["totalObtainedGraceMarks"] = subject.totalObtainedGraceMarks
            ? subject.totalObtainedGraceMarks
            : 0;
          subject["subjectCode"] = Number(subjectCode)
            ? Number(subjectCode)
            : subjectCode;
          subject["subjectName"] = subjectName;
          subject["subjectDescription"] = subjectDescription;
          subject["graceMarksSymbol"] = tempGraceMarksSymbol;
          subject["totalObtainedMarksWithGraceMarks"] =
            subject.totalObtainedMarks;
          subject["semNumber"] =
            this.currentBatchSemIds.indexOf(subjectSem) + 1;
          subject["semId"] = subjectSem;
          let tempCreditPoints = 0;
          let tempSubjectGradePoints = 0;
          if (subject.creditPoints) {
            tempCreditPoints = Number(subject.creditPoints);
          } else {
            tempCreditPoints = Number(subject.obtainedGradePoints);
          }
          if (subject.gradePoints !== undefined) {
            tempSubjectGradePoints = Number(subject.gradePoints).toFixed(1);
          } else {
            tempSubjectGradePoints = Number(subject.subjectGradePoints);
          }
          totalCreditPoints += tempCreditPoints;
          totalSubjectGradePoints += tempSubjectGradePoints;
          subjectCreditsSum += isNaN(subject.obtainedCredits)
            ? 0
            : subject.obtainedCredits;
          totalSubjectMarks += subject.totalMarks;
          totalSubjectObtainedMarks += subject.totalObtainedMarksWithGraceMarks;
          totalGradePointsSum += subject.gradePoints;

          if (this.currentInstitute === "RIIMS") {
            if (subject.totalExternalMarks === 0) {
              subject.totalObtainedExternalMarks = "NA";
            }
            if (subject.totalInternalMarks === 0) {
              subject.totalObtainedInternalMarks = "NA";
            }
          }
        }
        semesterSubjectMarks.map((item) => {
          let temp = this.removeOldResultFields(item);
          item = temp;
        });
        let tempGroupedSubjects = this.groupArrayByKey(
          semesterSubjectMarks,
          "semId"
        );
        let groupedSubjects = arrOps.sortByProperty(
          tempGroupedSubjects,
          "semName",
          "asc"
        );
        //   groupedSubjects.push(
        //     {
        //     semName: "SEM 3",
        //     subjects: [{},{},{},{},{},{},{},{},{},{},{}]
        //   },
        //   {
        //     semName: "SEM 4",
        //     subjects: [{},{},{},{}]
        //   }
        // )
        const semMap = new Map(groupedSubjects.map((sem) => [sem.semId, sem]));

        this.studentData.forEach((stu) => {
          if (stu.uId === student.uId && stu.semId === student.semId) {
            const sem = semMap.get(student.semId);
            if (sem) {
              // sem.finalPercentage = student.finalPercentage;
              sem.sgpa = student.finalSgpa.toFixed(1);
            }
          }
        });
        if (this.certificateNumber === 2) {
          let tempArr = groupedSubjects.filter(
            (item) => item.semId === this.selectedSemester.semId
          );
          groupedSubjects = tempArr;
        }
        // check this
        objToPush["maximumCredits"] = maximumCredits;
        objToPush["totalInternalMarks"] = this.totalInternalMarks;
        objToPush["totalExternalMarks"] = this.totalExternalMarks;
        objToPush["overallTotalCredits"] = 0;
        objToPush["overallObtainedCredits"] = 0;
        objToPush["totalGradePointsSum"] = totalGradePointsSum;
        objToPush["overallTotalMarks"] = 0;
        objToPush["overallObtainedMarks"] = 0;
        groupedSubjects.map((sem) => {
          sem.obtainedCredits = 0;
          sem.totalCredits = 0;
          sem.obtainedGradePointsSum = 0;
          sem.creditPointsSum = 0;
          sem.totalMarks = 0;
          sem.obtainedMarks = 0;
          sem.sgpa = student.allSgpas.filter(
            (item) => item.semId === sem.semId
          )[0]?.sgpa;
          sem.subjects.map((subject) => {
            sem.obtainedMarks += subject.totalObtainedMarks;
            sem.totalMarks += isNaN(subject.totalMarks)
              ? 0
              : subject.totalMarks;
            sem.obtainedGradePointsSum += subject.gradePoints;
            sem.creditPointsSum += subject.creditPoints;
            sem.totalCredits += this.batchSubjectDetails.filter(
              (sub) => sub.subjectId === subject.subjectId
            )[0]?.credits || 0;
            if (this.currentInstitute === "MIMA") {
            subject.isSeparatePassing = this.batchSubjectDetails.filter(
              (sub) => sub.subjectId === subject.subjectId
            )[0]?.isSeparatePassing || false;
            }
            sem.obtainedCredits += isNaN(subject.obtainedCredits)
              ? 0
              : subject.obtainedCredits;

              objToPush.overallTotalCredits += this.batchSubjectDetails.filter(
                (sub) => sub.subjectId === subject.subjectId
              )[0]?.credits;
              objToPush.overallObtainedCredits += isNaN(subject.obtainedCredits)
                ? 0
                : subject.obtainedCredits;
              objToPush.overallTotalMarks += isNaN(subject.totalMarks)
                ? 0
                : subject.totalMarks;
              objToPush.overallObtainedMarks += isNaN(subject.obtainedCredits)
                ? 0
                : subject.totalObtainedMarks;
            });
            sem.finalPercentage = numOps.calculatePercentage(
              sem.obtainedMarks,
              sem.totalMarks
            );
            sem.finalPercentage = this.roundOffUptoTwoDecimals(sem.finalPercentage).toFixed(2);
            // sem.finalGrade = sem.totalMarks/10;
            if (this.currentInstitute === "MIMA") {
              sem.finalGrade = this.assignGrade(
                sem.finalPercentage
              ).gradeLetter;
            }
            let studentSubjects
            if (this.currentInstitute === "MIMA" || this.currentInstitute === "RIIMS" || this.isStmiras) {
              const subjectsConverted = sem.subjects.map((subject) => ({
                ...subject,
                subjectDescription: !isNaN(subject.subjectDescription) && subject.subjectDescription !== ""
                  ? Number(subject.subjectDescription) 
                  : subject.subjectDescription,
              }));
              studentSubjects = arrOps.sortByProperty(subjectsConverted, "subjectDescription", "asc");
            } else {
              studentSubjects = arrOps.sortByProperty(
                sem.subjects,
                "subjectCode",
                "asc"
              );
            }
            sem.subjects = studentSubjects;
          });
        // }

        let tempPercentage =
          (objToPush.overallObtainedMarks / objToPush.overallTotalMarks) * 100;
        let percentage = isNaN(tempPercentage) ? 0 : tempPercentage;
        objToPush["finalPercentage"] = this.roundOffUptoTwoDecimals(percentage).toFixed(2);
        objToPush['finalGrade'] = this.assignGrade(percentage)?.gradeLetter || ""
        objToPush["groupedSubjects"] = groupedSubjects;
        objToPush["totalCredits"] = subjectCreditsSum;
        objToPush["semesterSubjectMarks"] = student.semesterSubjectMarks;
        objToPush["totalCreditPoints"] = totalCreditPoints;
        objToPush["totalSubjectGradePoints"] = totalSubjectGradePoints;
        objToPush["totalSubjectMarks"] = totalSubjectMarks;
        objToPush["totalSubjectObtainedMarks"] = this.roundOffUptoTwoDecimals(
          totalSubjectObtainedMarks
        );
        objToPush["finalSgpa"] = student.finalSgpa;
        let totalObtainedGradePoints = 0;
        let totalObtainedCreditsOfSubjects = 0;
        for (let j = 0; j < student.semesterSubjectMarks.length; j++) {
          const subject = student.semesterSubjectMarks[j];
          totalObtainedCreditsOfSubjects += isNaN(subject.obtainedCredits)
            ? 0
            : subject.obtainedCredits;
          let tempCreditPoints = 0;
          if (subject.creditPoints) {
            tempCreditPoints = Number(subject.creditPoints) || 0;
          } else {
            tempCreditPoints = Number(subject.obtainedGradePoints) || 0;
          }
          totalObtainedGradePoints += tempCreditPoints;
        }
        objToPush["totalObtainedCredits"] = totalObtainedCreditsOfSubjects;
        let cgpa = 0;
        // if(student.finalSgpa !== 0) {
        cgpa = this.calculateCgpa(
          totalObtainedGradePoints,
          totalObtainedCreditsOfSubjects
        );
        // }
        if (this.currentInstitute === "RIIMS") {
          objToPush["finalCgpa"] = cgpa;
          objToPush.allSgpas.forEach(sgpa => {
            if (sgpa.sgpa === 0) {
              objToPush["finalCgpa"] = 0;
              return;
            }
          });
        } else {
          objToPush["finalCgpa"] = cgpa;
        }
        if (this.certificateNumber === 2) {
          let totalSubjectObtainedMarks = 0;
          let totalSubjectMarks = 0;
          let totalCredits = 0;
          let subjectCreditsSum = 0;
          let maximumCredits = 0;
          let tempSgpaArray =
            objToPush.allSgpas.filter(
              (item) => item.semId === this.selectedSemester.semId
            ) || [];
          objToPush.allSgpas = tempSgpaArray;

          let tempArray = student.semesterSubjectMarks.filter(
            (item) => item.semId === this.selectedSemester.semId
          );
          if (this.currentInstitute === "MIMA" || this.currentInstitute === "RIIMS") {
              const subjectsConverted = tempArray.map((subject) => ({
                ...subject,
                subjectDescription: !isNaN(subject.subjectDescription) && subject.subjectDescription !== ""
                  ? Number(subject.subjectDescription) 
                  : subject.subjectDescription,
              }));
              objToPush.semesterSubjectMarks = arrOps.sortByProperty(subjectsConverted, "subjectDescription", "asc");
            } else {
              objToPush.semesterSubjectMarks = arrOps.sortByProperty(
                tempArray,
                "subjectCode",
                "asc"
              );
            }
          // if (this.currentInstitute !== "MIMA" && this.currentInstitute !== "RIIMS") {
          //   objToPush.semesterSubjectMarks = arrOps.sortByProperty(
          //     tempArray,
          //     "subjectCode",
          //     "asc"
          //   );
          // }

          tempArray.map((sub) => {
            let tempSubjectGradePoints = 0;
            for (
              let inSub = 0;
              inSub < this.instituteSubjects.length;
              inSub++
            ) {
              const subj = this.instituteSubjects[inSub];
              if (subj?.subjectId === sub?.subjectId) {
                maximumCredits += subj.credits;
              }
            }
            subjectCreditsSum += isNaN(sub.obtainedCredits)
              ? 0
              : sub.obtainedCredits;

            let tempObtainedMarks = 0;
            // if(sub.totalObtainedMarks) {
            totalSubjectObtainedMarks += sub.totalObtainedMarks;
            totalSubjectMarks += sub.totalMarks;
            // }
            if (sub.gradePoints !== undefined) {
              tempSubjectGradePoints = Number(sub.gradePoints);
            } else {
              tempSubjectGradePoints = Number(sub.subjectGradePoints);
            }
            objToPush["maximumCredits"] = maximumCredits;
            objToPush["totalCredits"] = subjectCreditsSum;
            objToPush["totalSubjectObtainedMarks"] = totalSubjectObtainedMarks;
            objToPush["totalSubjectMarks"] = totalSubjectMarks;
          });
          if (tempSgpaArray.length > 0) {
            if (tempSgpaArray[0].sgpa === 0) {
              objToPush["finalCgpa"] = 0;
            }
          } else {
            objToPush["finalCgpa"] = 0;
          }
        }
        // }
      }
      return objToPush;
      // }
    },
    removeOldResultFields(obj) {
      if (obj.obtainedGradePoints) {
        obj["creditPoints"] = obj.obtainedGradePoints;
        delete obj.obtainedGradePoints;
      }
      if (obj.obtainedMarks) {
        obj["totalObtainedMarks"] = obj.obtainedMarks;
        delete obj.obtainedMarks;
      }
      if (obj.subjectGradePoints) {
        obj["gradePoints"] = obj.subjectGradePoints.toFixed(1);
        delete obj.subjectGradePoints;
      }
      if (obj.totalCieMarks) {
        obj["totalInternalMarks"] = obj.totalCieMarks;
        delete obj.totalCieMarks;
      }
      if (obj.totalCieObtainedMarks) {
        obj["totalObtainedInternalMarks"] = obj.totalCieObtainedMarks;
        delete obj.totalCieObtainedMarks;
      }
      if (obj.totalSeeMarks) {
        obj["totalExternalMarks"] = obj.totalSeeMarks;
        delete obj.totalSeeMarks;
      }
      if (obj.totalSeeObtainedMarks) {
        obj["totalObtainedExternalMarks"] = obj.totalSeeObtainedMarks;
        delete obj.totalSeeObtainedMarks;
      }
      if (obj.obtainedMarks) {
        obj["totalObtainedMarks"] = obj.obtainedMarks;
        delete obj.obtainedMarks;
      }
      return obj;
    },
    removeUnnecessaryFields(semesterSubjectMarks) {
      let temp = semesterSubjectMarks.map((obj) => {
        const { subjectCode, ...rest } = obj;
        return rest;
      });
      return temp;
    },
    calculateCgpa(sumOfCreditPoints, sumOfCredits) {
      let cgpa = 0;
      if (sumOfCredits !== 0) {
        cgpa = this.roundOffUptoTwoDecimals(sumOfCreditPoints / sumOfCredits);
      }
      return cgpa;
    },
    groupArrayByKey(array, key) {
      if (key) {
        return Object.values(
          array.reduce((acc, item) => {
            let res = this.semestersListarr.filter(
              (elem) => elem.semId === item[key]
            )[0];
            // const groupKey = this.isStmiras
            //   ? this.modifiedSemesterName
            //   : res.semName;
            const groupKey = res.semName;
            if (!acc[groupKey]) {
              acc[groupKey] = {
                semName: groupKey.split(" ")[0] + " " + item.semNumber,
                subjects: [],
                semId: res.semId,
              };
            }
            acc[groupKey].subjects.push(item);
            return acc;
          }, {})
        );
      } else {
        return [];
      }
    },
    async selectStudent() {
      // const courseYearSemNameList = []
      this.disableAction = this.selectedStudent === "All" ? false : true;
      // if(this.isNicmar) {
      if (this.isStmiras) {
        const courseYearNumber = this.selectedBatch.batch.filter(
          (item) => item.courseYear === this.selectedCourseYear
        )[0].courseYearNumber;
        const [prefix, middle, postfix] = this.selectedSemesterName.split(" ");
        const temp = middle.split("/")[courseYearNumber - 1];
        this.modifiedSemesterName = `${prefix} ${temp} ${postfix}`;
      }
      this.marksheetDetailsToPush = {
        instituteName: this.instituteInfo.instituteName,
        instituteAddress: this.instituteInfo.instituteAddress,
        instituteLogo: this.instituteInfo.instituteLogoLink,
        departmentName: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        semesterName: this.isStmiras
          ? this.modifiedSemesterName
          : this.selectedSemesterName,
        marksheetTemplate: this.instituteInfo.marksheetTemplate,
        currentSemesterNumber: this.currentSemesterNumber,
      };
      // }
      this.studentDataLoader = true;
      await this.getSubjectDetails();
      if (this.selectedStudent !== "All") {
        const data = this.studentFullName.filter(
          (item) => item.uId === this.selectedStudent
        )[0];
        if (data) {
          this.selectedStudentUId = data.uId;
          await this.getSelectedStudentDetails(this.selectedStudentUId);
          await this.fetchData();
          this.getAssignmentAnalysis();
          await this.fetchExams();
          await this.selectSubject();
          this.setBarOptions();
          this.calculateStudentResult(this.selectedStudentUId);
          this.handleStudentFilter();
        }
      } else {
        await this.getSelectedStudentDetails("all");
        this.calculateStudentResult("all");
        this.handleStudentFilter();
      }
      this.isResultPublishForAllStudents = this.filteredStudents.every(
        (student) => student.publishResult
      );
      // if (
      //   this.instituteInfo.instituteId ===
      //     "ffd7e41c-d617-40f1-a2a4-f8d729662d49" ||
      //   this.instituteInfo.instituteId ===
      //     "86db902d-4346-4ef5-ad36-e1416d3306ae"
      // ) {
      //   this.customTemplateFor = "nicmar"
      // }
      // if (
      //   this.instituteInfo.instituteId ===
      //     "6604982c-cc08-4e00-81fb-a375a10b72e4"
      // ) {
      //   this.customTemplateFor = "stmiras"
      // }
      this.studentDataLoader = false;
    },
    async getSelectedStudentDetails(studentId) {
      this.userData = {};
      this.studentPersonalDetails = [];
      try {
        this.studentPersonalDetails =
          await this.userRepositoryInstance.getUsers({
            uIds: this.studentIdArray,
          });
        if (studentId !== "all") {
          this.userData = this.studentPersonalDetails.filter(
            (item) => item.uId === studentId
          )[0];
          // this.userData = this.selectedStudentDetails;
          // this.studentDOB = this.selectedStudentDetails.dateOfBirth.slice(0, 10);
        }
      } catch (error) {
        console.error("error", error);
      }
    },

    // Assignement Analysis

    async getAssignmentAnalysis() {
      const objToPush1 = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.selectedStudentUId,
      };
      try {
        this.assignmentAnalysis =
          await this.assignmentUserRepositoryInstance.assignmentAnalysis(
            objToPush1
          );
      } catch (err) {
        this.assignmentAnalysis = [];
        console.error(err);
      }
      const pctg =
        (this.assignmentAnalysis.totalSubmittedAssignmentCount /
          this.assignmentAnalysis.totalSubjectAssignmentCount) *
        100;
      if (isNaN(pctg)) {
        this.assignmentPercentage = 0;
      } else {
        this.assignmentPercentage = pctg;
      }
    },
    async selectAssignemntSubject() {
      this.assignemtAnalysisDetailsArray = [];
      this.assignmentSubjectNamesWithId.map((subject) => {
        if (this.assignemntSubjectName === subject.subName) {
          this.selectedAssignemntSubjectId = subject.subId;
        }
      });

      try {
        this.allSubmittedAssignments =
          await this.assignmentUserRepositoryInstance.getSubmittedAssignmentsOfAnUser(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: this.selectedStudentUId,
            }
          );
      } catch (error) {
        console.error("error", error);
        this.allSubmittedAssignments = [];
      }

      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.studentSemester.department,
        courseYear: this.studentSemester.courseYear,
        division: this.studentSemester.division,
        batch: this.studentSemester.batch,
        subjectId: this.selectedAssignemntSubjectId,
      };
      try {
        this.assignments =
          await this.assignmentRepositoryInstance.getAssignmentsOfADivisionOfASubject(
            objToPush
          );
      } catch (error) {
        this.assignments = [];
        console.error("error", error);
      }

      this.allSubmittedAssignments.map((submittedAssign) => {
        this.assignments.map((assign) => {
          if (submittedAssign.assignmentId === assign.assignmentId) {
            this.assignemtAnalysisDetailsArray.push({
              assignmentName: assign.title,
              submittedOn: submittedAssign.submittedOn,
              obtainedMarks: submittedAssign.marksObtained,
              totalMarks: assign.totalObtainableMarks,
            });
          }
        });
      });
    },
    async getAllSems() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          uId: this.userData.uId,
        };
        this.semestersList = [];
        this.semestersListarr =
          await this.semesterRepositoryInstance.getSemesterNames(objToPush);
        this.semestersListarr.map((obj) => {
          this.semestersList.push(obj.semName);
        });
      } catch (e) {
        console.error(e);
      }
    },
    async handleCourseYear() {
      this.batchSemestersList = []
      this.semesterUserDetails = [];
      this.studentResultData = [];
      this.selectedStudent = "";
      this.selectedSemesterName = "";
      this.currentBatchSemIds = [];
      this.semestersList = [];
      this.filteredStudents = [];
      this.selectedBatch.batch.forEach((element) => {
        if (this.selectedCourseYear === element.courseYear) {
          element.semesters.forEach((semester) => {
            this.semestersList.push(semester.semName);
          });
        }
          element.semesters.forEach((semester) => {
            this.batchSemestersList.push(semester.semName);
          });
      });
      let temp = arrOps.sortArray(this.semestersList, "asc");
      this.semestersList = temp.result;
      // let tempIndex = courseYearNumbers[this.selectedBatch.batch.filter(item => item.courseYear === this.selectedCourseYear)[0].year]
      if (
        this.selectedCourseYear === this.lastYearOfTheSelectedBatch.courseYear
      ) {
        this.isLastYear = true;
        for (let i = 0; i < this.selectedBatch.batch.length; i++) {
          const ele = this.selectedBatch.batch[i];
          if (this.certificateNumber === 4) {
            for (let j = 0; j < ele.semesters.length; j++) {
              if (ele.courseYear === this.selectedCourseYear) {
                const element = ele.semesters[j];
                this.currentBatchSemIds.push(element.semId);
                this.semestersToPush.push(element);
              }
            }
          } else {
            for (let j = 0; j < ele.semesters.length; j++) {
              const element = ele.semesters[j];
              this.currentBatchSemIds.push(element.semId);
              this.semestersToPush.push(element);
            }
          }
        }
      } else {
        this.isLastYear = false;
        const result = this.selectedBatch.batch.filter(
          (item) => item.courseYear === this.selectedCourseYear
        )[0];
        for (let index = 0; index < result.semesters.length; index++) {
          const element = result.semesters[index];
          this.currentBatchSemIds.push(element.semId);
          this.semestersToPush.push(element);
        }
        // const temp = this.selectedBatch.batch
        //   .filter((item) => item.courseYear === this.selectedCourseYear)[0]
        //   .semesters?.map((e) => e.semId);
      }
      // this.currentBatchSemIds = ["b1b72a5e-3e6e-4eaa-983b-1e578b142ee6"]
      await this.getSemesterDetails(this.currentBatchSemIds);
      await this.getSemesterSubjects();
      try {
        const result =
          await this.semesterUserRepositoryInstance.getStudentsOfMultipleSemesters(
            {
              instituteId: this.selectedInstitute,
              courseYear: this.selectedCourseYear,
              semIds: this.currentBatchSemIds,
            }
          );
        this.studentsForStatementNo = result;
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSemesterSubjects() {
      try {
        const data =
          await this.subjectsRepositoryInstance.getSubjectsOfSemestersWithoutCourseYear(
            {
              instituteId: this.selectedInstitute,
              semIds: this.currentBatchSemIds,
              department: this.selectedDepartment,
            }
          );
        this.batchSubjectDetails = data.subjects;
        let temp = data.subjects.filter(
          (item) =>
            item.totalCieMarks &&
            item.totalCieMarks > 0 &&
            item.totalSeeMarks &&
            item.totalSeeMarks > 0
        )[0];
        if (!temp) {
          showStatus(
            "Please enter Internal and External Marks for the subjects in Academic Management.",
            9000,
            "warning",
            this
          );
        }
        this.totalInternalMarks = temp.totalCieMarks || 0;
        this.totalExternalMarks = temp.totalSeeMarks || 0;
      } catch (error) {
        console.error("error", error);
      }
    },
    viewGradeRanges() {
      this.showGradeRangeDialog = true;
    },
    showSubjectDetails() {
      this.gradeRangeItems = [];
      if (
        this.examSettings.systemPreference === "cbs" &&
        this.examSettings.gradingSystem === "absolute"
      ) {
        this.gradeRangeItems = this.examSettings.gradeDetails;
      } else {
        if (this.selectedSubjectToShowGradeRange !== null) {
          this.gradeRangeItems =
            this.selectedSubjectToShowGradeRange.gradeRange;
        }
      }
    },
    async getSemesterDetails(semIds) {
      try {
        const response =
          await this.semesterRepositoryInstance.getMultipleSemesters({
            instituteId: this.selectedInstitute,
            semIds: semIds,
            courseYear: this.selectedCourseYear,
            department: this.selectedDepartment,
          });
        let result = response.every(this.checkIfResultIsPublished);
        for (let index = 0; index < response.length; index++) {
          const element = response[index];

          this.semesterResultStatus[element.semId] = element.isResultPublished;
        }
        result
          ? (this.resultCalculationMethod = "cgpa")
          : (this.resultCalculationMethod = "sgpa");
      } catch (error) {
        console.error("error", error);
      }
    },
    checkIfResultIsPublished(semester) {
      return semester.isResultPublished ? true : false;
    },
    handleBatchDetails() {
      this.allCourseYears = [];
      this.semestersList = [];
      this.selectedCourseYear = "";
      this.selectedSemesterName = "";
      this.selectedStudent = "";
      this.filteredStudents = [];
      this.selectedBatch.batch.map((item) => {
        item["courseYearNumber"] = courseYearNumbers[item.year];
        this.allCourseYears.push(item.courseYear);
      });

      this.lastYearOfTheSelectedBatch = this.selectedBatch.batch.sort(
        (a, b) => a.courseYearNumber - b.courseYearNumber
      )[this.selectedBatch.batch.length - 1];

      let allowedCourseYearForSelectedDepartment = [];
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split("_");
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course);
          }
        });
        this.allCourseYears = allowedCourseYearForSelectedDepartment;
      }
      let temp = arrOps.sortArray(this.allCourseYears, "asc");
      this.allCourseYears = temp.result;
    },
    handleDepartment() {
      this.selectedBatch = "";
      this.selectedCourseYear = "";
      this.selectedSemesterName = "";
      this.selectedStudent = "";
      this.semestersList = [];
      this.allBatches = this.batchData.filter(
        (item) => item.department === this.selectedDepartment
      );
      let temp = arrOps.sortByProperty(this.allBatches, "batchName", "asc");
      this.allBatches = temp;
    },
    async getBatches() {
      this.allDepartments = [];
      try {
        this.batchData = await this.nbaRepositoryInstance.getBatchesOfInstitute(
          {
            lmsInstituteId: this.selectedInstitute,
          }
        );
        // for (let index = 0; index < this.batchData.length; index++) {
        //   const element = this.batchData[index];

        //   this.allDepartments.push(element.department);
        // }
        this.allDepartments = this.batchData.map((item) => item.department);
        let subAdminAssignedDepartmentWiseLecture = [];
        if (this.assignedDepartment.length > 0) {
          this.allDepartments.map((data1) => {
            if (this.assignedDepartment.includes(data1)) {
              subAdminAssignedDepartmentWiseLecture.push(data1);
            }
          });
          this.allDepartments = subAdminAssignedDepartmentWiseLecture;
        }

        let temp = arrOps.sortArray(this.allDepartments, "asc");
        this.allDepartments = temp.result;
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSubjectDetails() {
      try {
        const result =
          await this.subjectsRepositoryInstance.getSubjectsOfAInstituteByInstituteId(
            {
              instituteId: this.selectedInstitute,
            }
          );
        this.instituteSubjects = result.subjects;
      } catch (error) {
        console.error("error", error);
      }
    },
    async fetchData() {
      try {
        this.semesters =
          await this.semesterUserRepositoryInstance.getSemesterUsersForAUser({
            instituteId: this.selectedInstitute,
            uId: this.selectedStudentUId,
          });
        this.semesters.map((sem) => {
          if (this.selectedSemester.semId === sem.semId) {
            this.studentSemester = sem;
          }
        });
        if (!this.studentSemester.semId) {
          showStatus("No Information Available", 9000, "warning", this);
        }
        const subjects = await this.subjectsRepositoryInstance.getSubjects({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: this.studentSemester.subjects,
        });
        this.subjectsMapping = {};
        this.selectedSubjectType = this.subjectTypes[0];
        subjects.map((subject) => {
          subject["subjectType"] = this.subjectTypes[subject.subjectType];
          this.subjectsMapping[subject.subjectId] = subject;
        });
      } catch (err) {
        console.error(err);
        this.semesters = [];
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.selectedStudentUId,
        };
        this.subjectIds = this.studentSemester.subjects;

        for (let i = 0; i < this.subjectIds?.length; i++) {
          const subjectId = this.subjectIds[i];

          if (this.subjectsMapping[subjectId] !== undefined) {
            if (
              this.subjectsMapping[subjectId].subjectType ===
              this.selectedSubjectType
            ) {
              this.barOptions.xaxis.categories.push(
                this.subjectsMapping[subjectId].subjectName +
                  " | " +
                  this.subjectsMapping[subjectId].subjectType
              );
            }
            this.subjectTypeWiseSubjects[
              this.subjectsMapping[subjectId].subjectType
            ].push(subjectId);
            this.subjects.push(
              this.subjectsMapping[subjectId].subjectName +
                " | " +
                this.subjectsMapping[subjectId].subjectType
            );
            this.subjectNamestoIds[
              this.subjectsMapping[subjectId].subjectName +
                " | " +
                this.subjectsMapping[subjectId].subjectType
            ] = subjectId;
            this.assignmentSubjectNames.push(
              this.subjectsMapping[subjectId].subjectName
            );
            this.assignmentSubjectNamesWithId.push({
              subName: this.subjectsMapping[subjectId].subjectName,
              subId: this.subjectsMapping[subjectId].subjectId,
            });
            this.examSubjectNamesWithId.push({
              subjectId: "all",
              subjectName: "All",
            });
            this.examSubjectNamesWithId.push({
              subjectName: this.subjectsMapping[subjectId].subjectName,
              subjectId: this.subjectsMapping[subjectId].subjectId,
            });
          }
        }
        if (!this.selectedSubjectString) {
          this.selectedSubjectString = this.subjects[0];
        }
      } catch (err) {
        console.error(err);
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.selectedStudentUId,
          department: this.studentSemester.department,
          courseYear: this.studentSemester.courseYear,
          division: this.studentSemester.division,
          batch: this.studentSemester.batch,
        };

        try {
          this.attendanceAnalysis =
            await this.lectureRepositoryInstance.getAttendanceAnalysisForAStudent(
              objToPush
            );
        } catch (err) {
          this.attendanceAnalysis = [];
          console.error(err);
        }

        for (let i = 0; i < this.attendanceAnalysis.length; i++) {
          const indexOfSubject = this.barOptions.xaxis.categories.indexOf(
            this.attendanceAnalysis[i].subjectId
          );

          if (indexOfSubject > -1) {
            this.barOptions.series[indexOfSubject].data =
              this.attendanceAnalysis[i].totalConducted > 0
                ? (
                    (this.attendanceAnalysis[i].totalAttended /
                      this.attendanceAnalysis[i].totalConducted) *
                    100
                  ).toFixed(0)
                : 0;
          } else {
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async setBarOptions() {
      this.isDefaulter = false;
      document.getElementById("chart").innerHTML = "<h1>loading</h1>";
      document.getElementById("bar").innerHTML = "<h1>loading</h1>";
      this.barOptions.xaxis.categories = [];
      this.barOptions.series[0].data = [];
      for (let i = 0; i < this.subjects.length; i++) {
        const subjectType = this.subjects[i].split(" | ")[1];
        if (this.selectedSubjectType === subjectType) {
          this.barOptions.xaxis.categories.push(this.subjects[i]);
        }
      }
      for (let i = 0; i < this.barOptions.xaxis.categories.length; i++) {
        this.barOptions.series[0].data[i] = 0;
      }
      this.totalPresent = 0;
      this.totalAbsent = 0;
      for (let i = 0; i < this.attendanceAnalysis.length; i++) {
        if (this.subjectsMapping[this.attendanceAnalysis[i].subjectId]) {
          this.totalPresent += parseInt(
            this.attendanceAnalysis[i].totalAttended
          );
          this.totalAbsent +=
            parseInt(this.attendanceAnalysis[i].totalConducted) -
            parseInt(this.attendanceAnalysis[i].totalAttended);
          const indexOfSubject = this.barOptions.xaxis.categories.indexOf(
            this.subjectsMapping[this.attendanceAnalysis[i].subjectId]
              .subjectName +
              " | " +
              this.subjectsMapping[this.attendanceAnalysis[i].subjectId]
                .subjectType
          );

          if (indexOfSubject > -1) {
            this.barOptions.series[0].data[indexOfSubject] =
              this.attendanceAnalysis[i].totalConducted > 0
                ? (
                    (this.attendanceAnalysis[i].totalAttended /
                      this.attendanceAnalysis[i].totalConducted) *
                    100
                  ).toFixed(0)
                : 0;
          }
        }
      }
      document.getElementById("chart").innerHTML = "";
      document.getElementById("bar").innerHTML = "";
      this.chartOptions.series = [this.totalPresent, this.totalAbsent];
      this.isDefaulter =
        (this.totalPresent / (this.totalAbsent + this.totalPresent)) * 100 <
        this.defaulterLimit;
      var chart = new ApexCharts(
        document.getElementById("chart"),
        this.chartOptions
      );
      var bar = new ApexCharts(document.getElementById("bar"), this.barOptions);
      bar.render();
      chart.render();
    },

    async setCardOptions() {
      this.attendanceAnalysisArray = [];
      this.isDefaulter = false;
      document.getElementById("chart").innerHTML = "<h1>loading</h1>";
      for (let i = 0; i < this.subjects.length; i++) {
        const subjectType = this.subjects[i].split(" | ")[1];
        const subject = this.subjects[i].split(" | ")[0];
        if (this.selectedSubjectType === subjectType) {
          const percentage = (
            (this.attendanceAnalysis[i].totalAttended /
              this.attendanceAnalysis[i].totalConducted) *
            100
          ).toFixed(2);
          this.attendanceAnalysisArray.push({
            subject: subject,
            percentage: percentage,
            subjectType: subjectType,
            totalAttended: this.attendanceAnalysis[i].totalAttended,
            totalConducted: this.attendanceAnalysis[i].totalConducted,
          });
        }
      }
      this.totalPresent = 0;
      this.totalAbsent = 0;

      for (let i = 0; i < this.attendanceAnalysis.length; i++) {
        if (this.subjectsMapping[this.attendanceAnalysis[i].subjectId]) {
          this.totalPresent += parseInt(
            this.attendanceAnalysis[i].totalAttended
          );
          this.totalAbsent +=
            parseInt(this.attendanceAnalysis[i].totalConducted) -
            parseInt(this.attendanceAnalysis[i].totalAttended);
          const indexOfSubject = this.barOptions.xaxis.categories.indexOf(
            this.subjectsMapping[this.attendanceAnalysis[i].subjectId]
              .subjectName +
              " | " +
              this.subjectsMapping[this.attendanceAnalysis[i].subjectId]
                .subjectType
          );

          if (indexOfSubject > -1) {
            this.barOptions.series[0].data[indexOfSubject] =
              this.attendanceAnalysis[i].totalConducted > 0
                ? (
                    (this.attendanceAnalysis[i].totalAttended /
                      this.attendanceAnalysis[i].totalConducted) *
                    100
                  ).toFixed(0)
                : 0;
          }
        }
      }
      document.getElementById("chart").innerHTML = "";
      this.chartOptions.series = [this.totalPresent, this.totalAbsent];
      this.isDefaulter =
        (this.totalPresent / (this.totalAbsent + this.totalPresent)) * 100 <
        this.defaulterLimit;
      var chart = new ApexCharts(
        document.getElementById("chart"),
        this.chartOptions
      );
      chart.render();
    },
    async setBarOptionsOne() {
      document.getElementById("chart").innerHTML = "<h1>loading</h1>";
      document.getElementById("bar").innerHTML = "<h1>loading</h1>";
      this.barOptions.xaxis.categories = [];
      this.barOptions.series[0].data = [];
      for (let i = 0; i < this.subjects.length; i++) {
        const subjectType = this.subjects[i].split(" | ")[1];
        if (this.selectedSubjectType === subjectType) {
          this.barOptions.xaxis.categories.push(this.subjects[i]);
        }
      }

      for (let i = 0; i < this.barOptions.xaxis.categories.length; i++) {
        this.barOptions.series[0].data[i] = 0;
      }
      this.totalPresent = 0;
      this.totalAbsent = 0;
      for (let i = 0; i < this.attendanceAnalysis.length; i++) {
        this.totalPresent += parseInt(this.attendanceAnalysis[i].totalAttended);
        this.totalAbsent +=
          parseInt(this.attendanceAnalysis[i].totalConducted) -
          parseInt(this.attendanceAnalysis[i].totalAttended);
        const indexOfSubject = this.barOptions.xaxis.categories.indexOf(
          this.subjectsMapping[this.attendanceAnalysis[i].subjectId]
            .subjectName +
            " | " +
            this.subjectsMapping[this.attendanceAnalysis[i].subjectId]
              .subjectType
        );

        if (indexOfSubject > -1) {
          this.barOptions.series[0].data[indexOfSubject] =
            this.attendanceAnalysis[i].totalConducted > 0
              ? (
                  (this.attendanceAnalysis[i].totalAttended /
                    this.attendanceAnalysis[i].totalConducted) *
                  100
                ).toFixed(0)
              : 0;
        }
      }
      document.getElementById("chart").innerHTML = "";
      document.getElementById("bar").innerHTML = "loading2";

      this.chartOptions.series = [this.totalPresent, this.totalAbsent];
      this.isDefaulter =
        (this.totalPresent / (this.totalAbsent + this.totalPresent)) * 100 <
        this.defaulterLimit;
      let chart = new ApexCharts(
        document.getElementById("chart"),
        this.chartOptions
      );
      chart.render();
      let bar = new ApexCharts(document.getElementById("bar"), this.barOptions);
      bar.render();
    },
    async selectSubject() {
      this.loading = true;
      const subjectAttendance = this.attendanceAnalysis.find(
        (elem) =>
          elem.subjectId === this.subjectNamestoIds[this.selectedSubjectString]
      );

      if (subjectAttendance) {
        this.totalAttendedLectures =
          subjectAttendance.totalAttended +
          "/" +
          subjectAttendance.totalConducted;
      }

      this.selectedSubject =
        this.subjectsMapping[
          this.subjectNamestoIds[this.selectedSubjectString]
        ];
      this.topicIds = [];
      this.topicsMapping = {};
      this.lectureIds = [];
      this.topicsData = [];
      this.lecturesList = {};

      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.studentSemester.department,
          courseYear: this.studentSemester.courseYear,
          division: this.studentSemester.division,
          batch: this.studentSemester.batch,
          subjectId: this.selectedSubject?.subjectId,
          uId: this.userData.uId,
        };
        let topics = [];
        this.topicIds = [];
        const tempTopics =
          await this.topicRepositoryInstance.getTopicsOfAStudent(objToPush);
        tempTopics.forEach((topic) => {
          this.lectureIds.push(topic.lectureId);
        });
        const objTogetLecturesBySubject = {
          lectures: this.lectureIds,
        };
        this.lecturesList =
          await this.lectureRepositoryInstance.getLecturesBySubject(
            objTogetLecturesBySubject
          );
        this.topicDetails = tempTopics.reduce((group, topic) => {
          const { topicId } = topic;
          this.topicIds.push(topicId);
          group[topicId] = group[topicId] ?? [];
          group[topicId].push(topic);
          return group;
        }, {});
        for (const [key, value] of Object.entries(this.topicDetails)) {
          const lectures = [];
          if (key !== undefined) {
            value.map((singleTopic) => {
              this.lecturesList.map((lecture) => {
                if (singleTopic.lectureId === lecture.lectureId) {
                  lectures.push(lecture);
                }
              });
            });
            value[0].lectures = lectures;
            this.topicsData.push(value[0]);
          }
        }
        this.topicsData.map((topic) => {
          let presentCount = 0;
          topic.lectures.map((lecture) => {
            if (lecture.present.includes(this.userData.uId)) {
              presentCount += 1;
            }
          });
          if (
            presentCount !== 0 &&
            topic.lectures.length === presentCount &&
            topic.isFeedbackAvailable === false
          ) {
            topic.topicFeedbackEnable = true;
          } else {
            topic.topicFeedbackEnable = false;
          }
        });
        (this.isTableData = false), (this.loading = false);
        this.topicsMapping = {};
        for (let i = 0; i < topics.length; i++) {
          const topic = topics[i];
          if (!this.topicIds.includes(topic.topicId)) {
            const feedback = {};
            const isFeedbackSubmitted = false;
            const isFeedbackAllowed = true;
            this.topicsMapping[topic.topicId] = {
              topicName: topic.topicName,
              isFeedbackSubmitted: isFeedbackSubmitted,
              isFeedbackAllowed: isFeedbackAllowed,
              feedback: feedback,
              lectures: [],
              topicId: topic.topicId,
              topicFeedbackEndDate: true,
            };
          }
          let lecture = {};
          try {
            lecture = await this.lectureRepositoryInstance.getLectureById(
              topic
            );
          } catch (err) {
            console.error(err);
          }
          this.topicsMapping[topic.topicId].lectures.push(lecture);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    convertDate(dateTime) {
      return convertDate(dateTime);
    },
    convertTime(dateTime) {
      return convertTime(dateTime);
    },
    async fetchExams() {
      try {
        this.isLoadingExams = true;
        if (
          this.instituteData.config &&
          this.instituteData.config.exams === "IN_HOUSE"
        ) {
          const response = await apiV3.getRequest("/exam/all", {
            category: "quick_exam",
            semId: this.selectedSemester.semId,
            studentId: this.selectedStudentUId,
          });
          await this.processQuickTests(response.exams);
          return;
        }
        const candidateID = this.userData.examCandidateID;
        const data = {
          studentId: candidateID,
        };
        const response = await this.examRepositoryInstance.getExams(data);
        this.exams = response.results;
        this.processExams(response.results);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingExams = false;
      }
    },
    async processQuickTests(exams) {
      const quickTest = [];
      const now = new Date();

      for await (const test of exams) {
        let state = "";
        let hasSubmitted = false;
        const examStartTime = parseISO(test.dateTimeUTC);

        if (isFuture(examStartTime)) {
          state = "pending";
          quickTest.push({ ...test, state, hasSubmitted });
          continue;
        }

        const endTime = addMinutes(examStartTime, test.durationInMins);
        if (compareAsc(endTime, now) === -1 || compareAsc(endTime, now) === 0) {
          state = "conducted";
          hasSubmitted = false;
          const response = await this.getExamAnswers(test._id);
          //array is an empty response from getExamAnswers
          if (!Array.isArray(response)) {
            hasSubmitted = true;
            const { totalMarksObtained, totalOutOf } = response;
            test.totalMarks = `${totalMarksObtained}/${totalOutOf}`;
            test.totalMarksObtained = totalMarksObtained;
            test.totalOutOf = totalOutOf;
          }
          quickTest.push({ ...test, state, hasSubmitted });
          continue;
        }

        const isOngoing = isWithinInterval(now, {
          start: examStartTime,
          end: endTime,
        });
        if (isOngoing) {
          state = "ongoing";
          hasSubmitted = false;
          const response = await this.getExamAnswers(test._id);
          //array is an empty response from getExamAnswers
          if (!Array.isArray(response)) {
            const { totalMarksObtained, totalOutOf } = response;
            test.totalMarks = `${totalMarksObtained}/${totalOutOf}`;
            hasSubmitted = true;
          }
          quickTest.push({ ...test, state, hasSubmitted });
          continue;
        }
      }
      this.quickTest = quickTest;
    },
    async getExamAnswers(examId) {
      try {
        const response = await apiV3.getRequest("/exam/answers", {
          studentId: this.selectedStudentUId,
          examId,
        });
        return response;
      } catch (e) {
        return [];
      }
    },
    async processExams(exams) {
      this.isLoadingExamsInfo = true;
      let promises = [];
      if (exams) {
        exams.forEach((exam) => {
          promises.push(
            this.getDetailsOfAnExam({
              examId: exam,
              studentId: this.selectedStudentUId,
            })
          );
        });
      }
      promises = await Promise.allSettled(promises);
      if (promises) {
        promises.forEach(async (exam) => {
          if (exam.value.examType === "Quick Test") {
            let obj = {
              instituteId: exam.value.instituteId,
              semId: exam.value.semId,
              examId: exam.value.examId,
            };
            this.quickTest.push(exam.value);
          } else {
            this.exams.push(exam.value);
          }
        });
      }
      for (let i = 0; i < promises.length; i++) {
        this.items.push(promises[i].value);
      }
      this.isLoadingExamsInfo = true;
    },
    async getDetailsOfAnExam({ examId, studentId }) {
      const objToReturn = examId;
      // Call Api to get Subject Name and Type
      if (this.subjectsMapping[examId?.subjectId]) {
        objToReturn.subjectName =
          this.subjectsMapping[examId?.subjectId].subjectName;
        objToReturn.subjectType =
          this.subjectsMapping[examId?.subjectId].subjectType;
        try {
          const examDetails =
            await this.examRepositoryInstance.getDetailsOfAnExam(examId);
          objToReturn.name = examDetails.name;
          objToReturn.examType = examDetails.type;
        } catch (err) {
          console.error(err);
        }
      } else {
        objToReturn.subjectName = "-";
        objToReturn.subjectType = "-";
        objToReturn.examType = "-";
      }
      // Call Api to get Exam Type
      return objToReturn;
    },
    async getExamsOfACriteria() {
      const data = {
        instituteId: this.userData.instituteId,
        semId: this.userData.semId,
        department: this.userData.department,
      };
      try {
        const examDetails =
          await this.examRepositoryInstance.getExamsOfACriteria(data);
        // objToReturn.name = examDetails.name
        if (examDetails) {
          examDetails.forEach(async (exam) => {
            if (exam.type === "Quick Test") {
              let obj = {
                instituteId: exam.instituteId,
                semId: exam.semId,
                examId: exam.examId,
              };
              let data = await this.getDetailsOfAnExam(obj);
              exam.subjectName = data.subjectName;
              this.quickTest.push(exam);
            } else {
              this.exams.push(exam);
            }
          });
        }
        // objToReturn.examType = examDetails.type
      } catch (err) {
        console.error(err);
      }
    },
    convertSecondstoTimeString(duration) {
      const hours = Math.floor(duration / 3600);
      duration %= 3600;
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      if (hours === 0) {
        // return minutes + ' Minutes ' + seconds + ' Seconds'
        return minutes + " Minutes ";
      } else {
        // return hours + ' Hours ' + minutes + ' Minutes ' + seconds + ' Seconds'
        return hours + " Hours " + minutes + " Minutes ";
      }
    },
    convertDateTOISO(date) {
      const d = new Date(date);
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    },
    onSelectSubject() {
      const categories = [];
      const marks = [];
      let sum = 0;
      this.examOptions.xaxis.categories = [];
      if (this.selectedSubjectId === "all") {
        for (const test of this.quickTest) {
          if (test.state === "conducted") {
            categories.push(test.name);
            if (isNaN(test.totalMarksObtained)) {
              marks.push(0);
              sum += 0;
            } else {
              marks.push(test.totalMarksObtained);
              sum += test.totalMarksObtained;
            }
          }
        }
      } else {
        const subjectName =
          this.subjectsMapping[this.selectedSubjectId].subjectName;
        for (const test of this.quickTest) {
          if (test.state === "conducted" && test.subjectName === subjectName) {
            categories.push(test.name);
            if (isNaN(test.totalMarksObtained)) {
              marks.push(0);
              sum += 0;
            } else {
              marks.push(test.totalMarksObtained);
              sum += test.totalMarksObtained;
            }
          }
        }
      }

      const AVG = (sum / marks.length).toFixed(2);
      if (isNaN(AVG)) {
        this.subjectAverage = 0;
      } else {
        this.subjectAverage = AVG;
      }
      this.examOptions = {
        ...this.examOptions,
        xaxis: { categories: categories.reverse() },
      };
      this.examSeries = [{ name: "marks", data: marks.reverse() }];
    },
    downloadPDF() {
      this.pdfDownloadLoader = true;
      html2pdf(this.$refs.studentMarkSheetRef, {
        // margin: [0, -1],
        filename: `${this.instituteInfo.instituteName}_${this.selectedBatch.batchName}_${this.selectedCourseYear}_StudentMarkSheet.pdf`,
        enableLinks: true,
        image: { type: "jpeg", quality: 0.7 },
        html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 600 },
        jsPDF: { unit: "in", orientation: "portrait", format: "a4" },
      });
      this.pdfDownloadLoader = false;
    },
    async generateStudentMarksheet() {
      this.marksheetLoader = true;
      // this.studentResultData = this.sortByAlphaNumericValue(
      //   this.studentResultData,
      //   "rollNumber"
      // );
      let modifiedArray = this.studentResultData.map((itm) => {
          if (this.currentInstitute === "MIMA") {
          // Reset cumulative variables for each student
          let totalMarks = 0;
          let totalMarksObtained = 0;
          let credits = 0;
          let egp = 0;
          let sgpa = 0;
          let finalGrade = "";
          // let cumulativeEgp = 0

          // Create a new objForCumulative for this iteration
          let objForCumulative = {
            semName: "Cumulative",
            semId: "id",
            totalMarks: 0,
            obtainedCredits: 0,
            obtainedGradePointsSum: 0,
            obtainedMarks: 0,
            finalPercentage: 0,
            totalCredits: 0,
            subjects: [],
            egp: 0,
            finalGrade: "",
            sgpa: 0,
          };

          // Check if Cumulative needs to be added
          if (
            itm?.groupedSubjects[itm?.groupedSubjects.length - 1]?.semName !==
            "Cumulative"
          ) {
            itm?.groupedSubjects?.push(objForCumulative);
          }
          let romanNumbers = {
            1: "I",
            2: "II",
            3: "III",
            4: "IV",
            5: "V",
            6: "VI",
            7: "VII",
            8: "VIII",
            9: "IX",
            10: "X",
          };
          itm?.groupedSubjects.forEach((sub) => {
            if (sub.resultPublicationDate && sub.resultPublicationDate !== "") {
              itm.resultPublicationDate = sub.resultPublicationDate;
            }
            if (Number(sub.semName[sub.semName.length - 1])) {
              let semNumber = romanNumbers[sub.semName[sub.semName.length - 1]];
              sub.semName = sub.semName.slice(0, -1) + semNumber; // replace last character
            }
            let semEgp = 0;
            if (this.currentInstitute === "MIMA" || this.currentInstitute === "RIIMS") {
              const subjectsConverted = sub.subjects.map((subject) => ({
                ...subject,
                subjectDescription: !isNaN(subject.subjectDescription) && subject.subjectDescription !== ""
                  ? Number(subject.subjectDescription) 
                  : subject.subjectDescription,
              }));
              sub.subjects = arrOps.sortByProperty(subjectsConverted, "subjectDescription", "asc");
            } else {
              sub.subjects = arrOps.sortByProperty(
                sub.subjects,
                "subjectCode",
                "asc"
              );
            }
            // sub.subjects = arrOps.sortByProperty(
            //   sub.subjects,
            //   "subjectCode",
            //   "asc"
            // );
            let tempResult = this.removeUnnecessaryFields(sub.subjects);
            sub.subjects = tempResult;
            if (this.currentInstitute === "MIMA") {
              sub?.subjects.sort((a, b) => {
                const aSatisfies = a.totalExternalMarks > itm.totalExternalMarks;
                const bSatisfies = b.totalExternalMarks > itm.totalExternalMarks;

                if (aSatisfies && !bSatisfies) {
                  return 1;
                }

                if (!aSatisfies && bSatisfies) {
                  return -1;
                }

                return 0;
              });
            }
            sub?.subjects.forEach((stuSub) => {

              if(stuSub.isSeparatePassing) {
                let internalPercentage = numOps.calculatePercentage(
                  stuSub.totalObtainedInternalMarks,
                  stuSub.totalInternalMarks
                );
                let externalPercentage = numOps.calculatePercentage(
                  stuSub.totalObtainedExternalMarks,
                  stuSub.totalExternalMarks
                );
                if(internalPercentage >= this.examSettings.minimumCiePercentage && externalPercentage >= this.examSettings.minimumSeePercentage) {
                  stuSub.obtainedGrade = this.assignGrade(stuSub.percentage).gradeLetter;
                  let calcGradePoint = stuSub.percentage / 10;
                  stuSub.gradePoints =
                numOps.roundOffUptoTwoDecimals(calcGradePoint).toFixed(1);
                } else {
                  stuSub.gradePoints = 0
                  stuSub.obtainedCredits = 0
                  stuSub.obtainedGrade = "F"
                }
              } else{
                stuSub.obtainedGrade = this.assignGrade(stuSub.percentage).gradeLetter;
                let calcGradePoint = stuSub.percentage / 10;
                  stuSub.gradePoints =
                numOps.roundOffUptoTwoDecimals(calcGradePoint).toFixed(1);
              }

              let calcGradePoint = stuSub.percentage / 10;
              if(!stuSub.isSeparatePassing) {
                if(stuSub.totalInternalMarks === 0) {
                  stuSub.totalObtainedExternalMarks = stuSub.totalObtainedMarks.toString() + "/" + stuSub.totalMarks.toString();
                  stuSub.totalObtainedInternalMarks = "-"
                } else if (stuSub.totalExternalMarks === 0){
                  stuSub.totalObtainedInternalMarks = stuSub.totalObtainedMarks.toString() + "/" + stuSub.totalMarks.toString();
                  stuSub.totalObtainedExternalMarks = "-"
                }
              } else if (this.currentInstitute === "MIMA") {
                if (stuSub.totalExternalMarks > itm.totalExternalMarks && stuSub.totalObtainedInternalMarks !== "-" && stuSub.totalObtainedExternalMarks !== "-") {
                  const tempObtainedInternal = parseFloat(stuSub.totalObtainedInternalMarks);
                  const tempObtainedExternal = parseFloat(stuSub.totalObtainedExternalMarks);
                  const temptotalObtained = parseFloat(stuSub.totalObtainedMarks);
                  const temptotal = parseFloat(stuSub.totalMarks);
                  const tempInternal = parseFloat(stuSub.totalInternalMarks);
                  const tempExternal = parseFloat(stuSub.totalExternalMarks);

                  // Create new strings without using any old strings that may contain previous data
                  stuSub.totalObtainedExternalMarks = tempObtainedExternal + "/" + tempExternal;
                  stuSub.totalObtainedInternalMarks = tempObtainedInternal + "/" + tempInternal;
                  stuSub.totalObtainedMarks = temptotalObtained + "/" + temptotal;
                }
              }
              stuSub.creditPoints = stuSub.gradePoints * stuSub.obtainedCredits;
              semEgp += stuSub.creditPoints;
            });

            if (sub.semName !== "Cumulative") {
              totalMarks += sub.totalMarks || 0;
              totalMarksObtained += sub.obtainedMarks || 0;
              credits += sub.obtainedCredits || 0;
              sub.egp = numOps.roundOffUptoTwoDecimals(semEgp);
              sub.sgpa =
                sub.obtainedCredits
                  ? Math.floor((sub.egp / sub.obtainedCredits) * 10) / 10
                  : 0;
                if(isNaN(sub.sgpa)) sub.sgpa = 0
              egp += semEgp;
              if(this.currentInstitute === "MIMA") {
                if (sub.sgpa >= 8.5) {
                  sub.finalGrade = "O";
                } else if (sub.sgpa >= 7.0) {
                  sub.finalGrade = "A+";
                } else if (sub.sgpa >= 6.0) {
                  sub.finalGrade = "A";
                } else if (sub.sgpa >= 5.5) {
                  sub.finalGrade = "B+";
                } else if (sub.sgpa >= 5.0) {
                  sub.finalGrade = "B";
                } else if (sub.sgpa >= 4.1) {
                  sub.finalGrade = "C";
                } else if (sub.sgpa === 4.0) {
                  sub.finalGrade = "P";
                } else {
                  sub.finalGrade = "F";
                }
              }
            }
          });

          // Update objForCumulative with calculated values
          objForCumulative.totalMarks = totalMarks;
          objForCumulative.obtainedMarks = totalMarksObtained;
          objForCumulative.totalCredits = credits;
          objForCumulative.egp = numOps.roundOffUptoTwoDecimals(egp);
          objForCumulative.sgpa =
            (
              objForCumulative.egp / objForCumulative.totalCredits
            ).toFixed(1) || 0;
          objForCumulative.finalPercentage = numOps.calculatePercentage(
            totalMarksObtained,
            totalMarks
          );
          objForCumulative.finalPercentage = this.roundOffUptoTwoDecimals(objForCumulative.finalPercentage).toFixed(2);
          // sem.finalGrade = this.assignGrade(sem.finalPercentage);
          let temp = this.assignGrade(objForCumulative.finalPercentage);
          if(this.currentInstitute === "MIMA") {
            if (objForCumulative.sgpa >= 8.5) {
              objForCumulative.finalGrade = "O";
            } else if (objForCumulative.sgpa >= 7.0) {
              objForCumulative.finalGrade = "A+";
            } else if (objForCumulative.sgpa >= 6.0) {
              objForCumulative.finalGrade = "A";
            } else if (objForCumulative.sgpa >= 5.5) {
              objForCumulative.finalGrade = "B+";
            } else if (objForCumulative.sgpa >= 5.0) {
              objForCumulative.finalGrade = "B";
            } else if (objForCumulative.sgpa >= 4.1) {
              objForCumulative.finalGrade = "C";
            } else if (objForCumulative.sgpa === 4.0) {
              objForCumulative.finalGrade = "P";
            } else {
              objForCumulative.finalGrade = "F";
            }
          }
          // objForCumulative.finalGrade = temp.gradeLetter;
          itm.finalResult = temp.gradeRemark;
          itm.finalGrade = objForCumulative.finalGrade;
        }

        const modifiedObj = { ...itm };
        if (this.currentInstitute === "MIMA" || this.currentInstitute === "RIIMS") {
              const subjectsConverted = modifiedObj.semesterSubjectMarks.map((subject) => ({
                ...subject,
                subjectDescription: !isNaN(subject.subjectDescription) && subject.subjectDescription !== ""
                  ? Number(subject.subjectDescription) 
                  : subject.subjectDescription,
              }));
              modifiedObj.semesterSubjectMarks = arrOps.sortByProperty(subjectsConverted, "subjectDescription", "asc");
            } else {
              modifiedObj.semesterSubjectMarks = arrOps.sortByProperty(
                modifiedObj.semesterSubjectMarks,
                "subjectCode",
                "asc"
              );
            }
        // if (this.currentInstitute === "RIIMS") {
        //   modifiedObj.semesterSubjectMarks = arrOps.sortByProperty(
        //     modifiedObj.semesterSubjectMarks,
        //     "subjectCode",
        //     "asc"
        //   );
        // }

        if (this.customMarksheetTemplate === 0) {
          delete modifiedObj["groupedSubjects"];
        } else {
          delete modifiedObj["semesterSubjectMarks"];
        }

        return modifiedObj;

      });
      let flag = false;
      const objToPush = {
        studentData: modifiedArray,
        instituteData: {
          instituteName: this.instituteInfo.instituteName,
          batchName: this.selectedBatch.batchName,
          courseYear: this.selectedCourseYear,
          instituteAddress: this.instituteInfo.instituteAddress,
          instituteLogoLink: this.instituteLogoLink,
          department: this.selectedDepartment,
          customMarksheetTemplate: this.customMarksheetTemplate,
          resultPublicationDate: this.resultPublicationDate,
          // isNicmar: this.isNicmar,
          // customTemplateFor: this.customTemplateFor,
          semesterName: this.selectedSemesterName,
          marksheetTemplate: this.instituteInfo.marksheetTemplate,
          instituteAddress: this.instituteInfo.instituteAddress,
          currentSemesterNumber: this.currentSemesterNumber,
        },
      };
      try {
        const result = await apiV3.postRequest(
          "/manageExam/generateStudentMarksheet",
          objToPush
        );
        this.openInNewTab(result.data.pdfUrl);
        // window.open(result.data.pdfUrl);
        // this.download(result.data.pdfUrl, 'temp')
        flag = true;
      } catch (error) {
        console.error("error", error);
        showStatus(
          "Error generating the marksheet. Please try again after some time.",
          8000,
          "error",
          this
        );
        flag = false;
      }
      // to update semester users statement no in st miras
      if (this.isStmiras === true) {
        if (flag) {
          let temp = [];
          objToPush.studentData.map((student1) => {
            this.studentsForStatementNo.map((student2) => {
              let obj = {};
              if (student1.uId === student2.uId) {
                obj["instituteId"] = student2.instituteId;
                obj["semId"] = student2.semId;
                obj["uId"] = student2.uId;
                obj["statementNumber"] = student1.statementNumber;
                obj["department"] = student2.department;
                temp.push(obj);
              }
            });
          });
          if (temp.length > 0) {
            try {
              await this.semesterUserRepositoryInstance.updateSemesterUser({
                bulkDataArray: temp,
              });
            } catch (error) {
              console.error("error", error);
            }
          } // async function downloadFile(url, fileName) {
          //     try {
          //       const response = await fetch(url);

          //       if (!response.ok) {
          //         throw new Error(`Network response was not ok, status: ${response.status}`);
          //       }

          //       const blob = await response.blob();
          //       const downloadUrl = URL.createObjectURL(blob);
          //       const a = document.createElement('a');
          //       a.href = downloadUrl;
          //       a.download = fileName;
          //       document.body.appendChild(a);
          //       a.click();
          //       document.body.removeChild(a);
          //       URL.revokeObjectURL(downloadUrl);
          //     } catch (error) {
          //       console.error('There was an error downloading the file:', error);
          //     }
          //   }
        }
      }
      this.marksheetLoader = false;
      // const fileName = `${objToPush.instituteData.instituteName}/${objToPush.instituteData.batchName}/${objToPush.instituteData.courseYear}_studentMarksheet.pdf`;
      // this.download(result.data.pdfUrl, fileName);
    },
    openInNewTab(url) {
      window.open(url, "_blank").focus();
    },
    download(dataurl, filename) {
      const link = document.createElement("a");
      link.href = dataurl;
      link.download = filename;
      link.click();
    },
    downloadFileViaBuffer(fileBuffer, fileName) {
      const pdfBlob2 = new Blob([fileBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob2);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
<style scoped src="./activityStudentProfile.css"></style>
